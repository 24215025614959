import React from "react";
import { Button, Form, Input } from "antd";
import "./styles.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginUser } from "../../../redux/actions/userActions";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const goToHome = () => navigate("/");
  const onFinish = (values) => {
    loginUser(values, goToHome, dispatch);
  };

  return (
    <div className="auth-form">
      <Form
        name="basic"
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Adresse Email"
          name="email"
          rules={[
            {
              type: "email",
              required: true,
              message: "Renseignez votre adresse Email!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Mot de passe"
          name="password"
          rules={[
            { required: true, message: "Renseignez votre mot de passe!" },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            S'identifier
          </Button>
        </Form.Item>
        <Link to="/forgot-password">Mot de passe oublié</Link>
      </Form>
    </div>
  );
}

export default Login;
