import { Button, Col, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import { defaultSchedule, scheduleOrder, renderDate } from "./defaultSchedule";
import { updateSchedule } from "../../../redux/actions/categoryActions";

function ScheduleModal(props) {
  const dispatch = useDispatch();
  const { closeModal, toggleModal } = props;
  const { schedule } = useSelector((state) => state.userReducer);
  const [activeSchedule, setActiveSchedule] = useState(defaultSchedule);

  useEffect(() => {
    if (schedule) {
      setActiveSchedule(schedule);
    }
  }, [schedule]);

  const onChangeSchedule = (value, day, moment, label) => {
    setActiveSchedule((prevState) => ({
      ...prevState,
      [day]: {
        ...prevState[day],
        [moment]: {
          ...prevState[day][moment],
          [label]:
            label === "from" || label === "to"
              ? new Date(value).toISOString()
              : value,
        },
      },
    }));
  };
  const onSaveSchedule = () => {
    updateSchedule(activeSchedule, dispatch);
    closeModal();
  };

  return (
    <Modal
      title={
        toggleModal.category ? "Modifier le service" : "Ajouter un service"
      }
      open={true}
      onCancel={closeModal}
      footer={() => null}
      width={900}
    >
      <div>
        <Row style={{ border: "1px solid gray" }}>
          <Col span={4} style={{ borderRight: "1px solid gray", padding: 5 }}>
            Jour
          </Col>
          <Col span={10}>
            <div
              style={{
                borderRight: "1px solid gray",
                borderBottom: "1px solid gray",
                padding: 5,
              }}
            >
              Matin
            </div>
            <Row>
              <Col
                span={6}
                style={{ borderRight: "1px solid gray", padding: 5 }}
              >
                Ouvert
              </Col>
              <Col
                span={9}
                style={{ borderRight: "1px solid gray", padding: 5 }}
              >
                De
              </Col>
              <Col
                span={9}
                style={{ borderRight: "1px solid gray", padding: 5 }}
              >
                À
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <div
              style={{
                borderBottom: "1px solid gray",
                padding: 5,
              }}
            >
              Après-midi
            </div>
            <Row>
              <Col
                span={6}
                style={{ borderRight: "1px solid gray", padding: 5 }}
              >
                Ouvert
              </Col>
              <Col
                span={9}
                style={{ borderRight: "1px solid gray", padding: 5 }}
              >
                De
              </Col>
              <Col span={9} style={{ padding: 5 }}>
                À
              </Col>
            </Row>
          </Col>
        </Row>
        {scheduleOrder.map((day) =>
          renderDate(day, activeSchedule, onChangeSchedule)
        )}
      </div>
      <Button onClick={onSaveSchedule}>Enregistrer</Button>
    </Modal>
  );
}

export default ScheduleModal;
