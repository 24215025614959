import { Button, Form, Input, Modal } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateEmail } from "../../../redux/actions/userActions";

function EmailModal(props) {
  const { user, token } = useSelector((state) => state.userReducer);
  const { closeModal } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onUpdateEmail = (values) => {
    updateEmail(token, values, closeModal, dispatch);
  };

  return (
    <Modal
      title="Modifier mon adresse Email"
      open={true}
      onCancel={closeModal}
      width={500}
      footer={() => null}
    >
      <Form
        layout="vertical"
        onFinish={onUpdateEmail}
        form={form}
        initialValues={{
          email: user.email,
          password: "",
        }}
      >
        <Form.Item
          label="Adresse Email"
          name="email"
          rules={[
            {
              type: "email",
              required: true,
              message: "Veuillez renseigner votre adresse Email!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Mot de passe"
          name="password"
          rules={[
            {
              required: true,
              message: "Veuillez renseigner votre mot de passe!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Enregister
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default EmailModal;
