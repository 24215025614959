import { Button, Form, InputNumber, Modal, Switch } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import "./styles.css";
import {
  addRealisation,
  addRealisationImage,
  getRealisation,
  updateRealisation,
} from "../../../redux/actions/categoryActions";
import { useDispatch } from "react-redux";

function RealisationModal(props) {
  const { closeModal, toggleModal } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [realisationImage, setRealisationImage] = useState(null);

  useEffect(() => {
    if (toggleModal.category) {
      getRealisation(toggleModal.category)
        .then((res) => {
          let fields = form.getFieldsValue();
          fields = {
            ...fields,
            order: res.data.order,
            active: res.data.active,
            image: res.data.image,
          };
          setRealisationImage(res.data.image);
          form.setFieldsValue(fields);
        })
        .catch((err) => console.log(err));
    }
  }, [toggleModal, form]);

  const onChangeRealisationImage = (data) => {
    const image = data.target.files[0];
    const formData = new FormData();
    formData.append("image", image);
    addRealisationImage(formData).then((res) => {
      let fields = form.getFieldsValue();
      fields = {
        ...fields,
        image: res.data,
      };
      form.setFieldsValue(fields);
      setRealisationImage(res.data);
    });
  };

  const onAddRealisation = (body) => {
    if (toggleModal.category) {
      updateRealisation(toggleModal.category, body, closeModal, dispatch);
    } else {
      addRealisation(body, closeModal, dispatch);
    }
  };

  return (
    <Modal
      title={
        toggleModal.category ? "Modifier la catégorie" : "Ajouter une catégorie"
      }
      open={true}
      onCancel={closeModal}
      footer={() => null}
    >
      <Form layout="vertical" onFinish={onAddRealisation} form={form}>
        <Form.Item
          label="Photo"
          name="image"
          rules={[
            {
              required: true,
              message: "Veuillez ajouter l'image principale de la catégorie!",
            },
          ]}
        >
          <div>
            <input
              className="product_form_input_none"
              onChange={(e) => onChangeRealisationImage(e)}
              type="file"
              id="new_category_image"
              name="new_category_image"
            />
            <label htmlFor="new_category_image">
              {realisationImage ? (
                <img
                  src={realisationImage}
                  alt="preview logo"
                  className="product_form_image"
                />
              ) : (
                <div className="product_form_image">+</div>
              )}
            </label>
          </div>
        </Form.Item>
        <Form.Item
          label="Ordre d'affichage"
          name="order"
          tooltip={{
            title:
              "Cette valeur permet de classer l'ordre d'affichage sur le site",
            icon: <InfoCircleOutlined />,
          }}
        >
          <InputNumber placeholder="1" style={{ width: "100%" }} />
        </Form.Item>
        {toggleModal.category && (
          <Form.Item
            label="Active"
            name="active"
            tooltip={{
              title:
                "Cette valeur permet de classer l'ordre d'affichage sur le site",
              icon: <InfoCircleOutlined />,
            }}
          >
            <Switch />
          </Form.Item>
        )}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Enregister
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default RealisationModal;
