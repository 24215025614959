export const monthsList = [
  { name: "Janvier", number: "01", id: 0 },
  { name: "Février", number: "02", id: 1 },
  { name: "Mars", number: "03", id: 2 },
  { name: "Avril", number: "04", id: 3 },
  { name: "Mai", number: "05", id: 4 },
  { name: "Juin", number: "06", id: 5 },
  { name: "Juillet", number: "07", id: 6 },
  { name: "Août", number: "08", id: 7 },
  { name: "Septembre", number: "09", id: 8 },
  { name: "Octobre", number: "10", id: 9 },
  { name: "Novembre", number: "11", id: 10 },
  { name: "Décembre", number: "12", id: 11 },
];
export const daysList = [
  { name: "Lundi", shortName: "Lun", id: 1 },
  { name: "Mardi", shortName: "Mar", id: 2 },
  { name: "Mercredi", shortName: "Mer", id: 3 },
  { name: "Jeudi", shortName: "Jeu", id: 4 },
  { name: "Vendredi", shortName: "Ven", id: 5 },
  { name: "Samedi", shortName: "Sam", id: 6 },
  { name: "Dimanche", shortName: "Dim", id: 0 },
];

export const filteredDate = (value) => {
  const date = new Date(value);
  const activeDate = date.getDate() < 10 ? 0 + date.getDate() : date.getDate();
  const activeDay =
    daysList[daysList.findIndex((item) => item.id === date.getDay())];
  const activeMonth =
    monthsList[monthsList.findIndex((item) => item.id === date.getMonth())];
  const activeYear = date.getFullYear();
  return `${activeDay.shortName} ${activeDate} ${activeMonth.name} ${activeYear}`;
};
