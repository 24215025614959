export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILLED = "LOGIN_USER_FAILLED";

export const UPDATE_INFORMATIONS_REQUEST = "UPDATE_INFORMATIONS_REQUEST";
export const UPDATE_INFORMATIONS_SUCCESS = "UPDATE_INFORMATIONS_SUCCESS";
export const UPDATE_INFORMATIONS_FAILLED = "UPDATE_INFORMATIONS_FAILLED";

export const GET_INFORMATIONS_REQUEST = "GET_INFORMATIONS_REQUEST";
export const GET_INFORMATIONS_SUCCESS = "GET_INFORMATIONS_SUCCESS";
export const GET_INFORMATIONS_FAILLED = "GET_INFORMATIONS_FAILLED";

export const UPDATE_SCHEDULE_REQUEST = "UPDATE_SCHEDULE_REQUEST";
export const UPDATE_SCHEDULE_SUCCESS = "UPDATE_SCHEDULE_SUCCESS";
export const UPDATE_SCHEDULE_FAILLED = "UPDATE_SCHEDULE_FAILLED";

export const GET_CATEGORIES_REQUEST = "GET_CATEGORIES_REQUEST";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAILLED = "GET_CATEGORIES_FAILLED";

export const ADD_CATEGORY_REQUEST = "ADD_CATEGORY_REQUEST";
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS";
export const ADD_CATEGORY_FAILLED = "ADD_CATEGORY_FAILLED";

export const UPDATE_CATEGORY_REQUEST = "UPDATE_CATEGORY_REQUEST";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAILLED = "UPDATE_CATEGORY_FAILLED";

export const GET_SUB_CATEGORIES_REQUEST = "GET_SUB_CATEGORIES_REQUEST";
export const GET_SUB_CATEGORIES_SUCCESS = "GET_SUB_CATEGORIES_SUCCESS";
export const GET_SUB_CATEGORIES_FAILLED = "GET_SUB_CATEGORIES_FAILLED";

export const ADD_SUB_CATEGORY_REQUEST = "ADD_SUB_CATEGORY_REQUEST";
export const ADD_SUB_CATEGORY_SUCCESS = "ADD_SUB_CATEGORY_SUCCESS";
export const ADD_SUB_CATEGORY_FAILLED = "ADD_SUB_CATEGORY_FAILLED";

export const UPDATE_SUB_CATEGORY_REQUEST = "UPDATE_SUB_CATEGORY_REQUEST";
export const UPDATE_SUB_CATEGORY_SUCCESS = "UPDATE_SUB_CATEGORY_SUCCESS";
export const UPDATE_SUB_CATEGORY_FAILLED = "UPDATE_SUB_CATEGORY_FAILLED";

export const GET_SERVICES_REQUEST = "GET_SERVICES_REQUEST";
export const GET_SERVICES_SUCCESS = "GET_SERVICES_SUCCESS";
export const GET_SERVICES_FAILLED = "GET_SERVICES_FAILLED";

export const ADD_SERVICE_REQUEST = "ADD_SERVICE_REQUEST";
export const ADD_SERVICE_SUCCESS = "ADD_SERVICE_SUCCESS";
export const ADD_SERVICE_FAILLED = "ADD_SERVICE_FAILLED";

export const UPDATE_SERVICE_REQUEST = "UPDATE_SERVICE_REQUEST";
export const UPDATE_SERVICE_SUCCESS = "UPDATE_SERVICE_SUCCESS";
export const UPDATE_SERVICE_FAILLED = "UPDATE_SERVICE_FAILLED";

export const GET_PRESTATRICES_REQUEST = "GET_PRESTATRICES_REQUEST";
export const GET_PRESTATRICES_SUCCESS = "GET_PRESTATRICES_SUCCESS";
export const GET_PRESTATRICES_FAILLED = "GET_PRESTATRICES_FAILLED";

export const ADD_PRESTATRICE_REQUEST = "ADD_PRESTATRICE_REQUEST";
export const ADD_PRESTATRICE_SUCCESS = "ADD_PRESTATRICE_SUCCESS";
export const ADD_PRESTATRICE_FAILLED = "ADD_PRESTATRICE_FAILLED";

export const UPDATE_PRESTATRICE_REQUEST = "UPDATE_PRESTATRICE_REQUEST";
export const UPDATE_PRESTATRICE_SUCCESS = "UPDATE_PRESTATRICE_SUCCESS";
export const UPDATE_PRESTATRICE_FAILLED = "UPDATE_PRESTATRICE_FAILLED";

export const GET_REALISATIONS_REQUEST = "GET_REALISATIONS_REQUEST";
export const GET_REALISATIONS_SUCCESS = "GET_REALISATIONS_SUCCESS";
export const GET_REALISATIONS_FAILLED = "GET_REALISATIONS_FAILLED";

export const ADD_REALISATION_REQUEST = "ADD_REALISATION_REQUEST";
export const ADD_REALISATION_SUCCESS = "ADD_REALISATION_SUCCESS";
export const ADD_REALISATION_FAILLED = "ADD_REALISATION_FAILLED";

export const UPDATE_REALISATION_REQUEST = "UPDATE_REALISATION_REQUEST";
export const UPDATE_REALISATION_SUCCESS = "UPDATE_REALISATION_SUCCESS";
export const UPDATE_REALISATION_FAILLED = "UPDATE_REALISATION_FAILLED";

export const GET_RESERVATIONS_REQUEST = "GET_RESERVATIONS_REQUEST";
export const GET_RESERVATIONS_SUCCESS = "GET_RESERVATIONS_SUCCESS";
export const GET_RESERVATIONS_FAILLED = "GET_RESERVATIONS_FAILLED";

export const RESERVATION_REQUEST = "RESERVATION_REQUEST";
export const RESERVATION_SUCCESS = "RESERVATION_SUCCESS";
export const RESERVATION_FAILLED = "RESERVATION_FAILLED";

export const LOGOUT_USER = "LOGOUT_USER";