import { Col, Row, Switch, TimePicker } from "antd";
import moment from "moment";
const format = "HH:mm";

export const defaultSchedule = {
  monday: {
    index: 1,
    name: "Lundi",
    short: "Lun",
    morning: {
      open: true,
      from: "",
      to: "",
    },
    afternoon: {
      open: true,
      from: "",
      to: "",
    },
  },
  tuesday: {
    index: 2,
    name: "Mardi",
    short: "Mar",
    morning: {
      open: true,
      from: "",
      to: "",
    },
    afternoon: {
      open: true,
      from: "",
      to: "",
    },
  },
  wednesday: {
    index: 3,
    name: "Mercredi",
    short: "Mer",
    morning: {
      open: true,
      from: "",
      to: "",
    },
    afternoon: {
      open: true,
      from: "",
      to: "",
    },
  },
  thursday: {
    index: 4,
    name: "Jeudi",
    short: "Jeu",
    morning: {
      open: true,
      from: "",
      to: "",
    },
    afternoon: {
      open: true,
      from: "",
      to: "",
    },
  },
  friday: {
    index: 5,
    name: "Vendredi",
    short: "Ven",
    morning: {
      open: true,
      from: "",
      to: "",
    },
    afternoon: {
      open: true,
      from: "",
      to: "",
    },
  },
  saturday: {
    index: 6,
    name: "Samedi",
    short: "Sam",
    morning: {
      open: true,
      from: "",
      to: "",
    },
    afternoon: {
      open: true,
      from: "",
      to: "",
    },
  },
  sunday: {
    index: 0,
    name: "Dimanche",
    short: "Dim",
    morning: {
      open: true,
      from: "",
      to: "",
    },
    afternoon: {
      open: true,
      from: "",
      to: "",
    },
  },
};

export const scheduleOrder = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

export const renderDate = (day, activeSchedule, onChangeSchedule) => {
  const activeDay = activeSchedule[day];
  return (
    <Row
      key={activeDay.index}
      style={{
        borderLeft: "1px solid lightgray",
        borderRight: "1px solid lightgray",
        borderBottom: "1px solid lightgray",
      }}
    >
      <Col
        xl={4}
        xs={5}
        style={{ borderRight: "1px solid lightgray", padding: 5 }}
      >
        {activeDay.short}
      </Col>
      <Col xl={10} xs={19}>
        <Row>
          <Col
            xs={6}
            xl={6}
            style={{ borderRight: "1px solid lightgray", padding: 5 }}
          >
            <Switch
              value={activeDay.morning.open}
              onChange={(e) => onChangeSchedule(e, day, "morning", "open")}
            />
          </Col>
          <Col
            xs={9}
            xl={9}
            style={{ borderRight: "1px solid lightgray", padding: 5 }}
          >
            <TimePicker
              format={format}
              placeholder="09:00"
              disabled={!activeDay.morning.open}
              value={
                activeDay?.morning?.from ? moment(activeDay.morning.from) : ""
              }
              onChange={(e) => onChangeSchedule(e, day, "morning", "from")}
            />
          </Col>
          <Col
            xs={9}
            xl={9}
            style={{ borderRight: "1px solid lightgray", padding: 5 }}
          >
            <TimePicker
              format={format}
              placeholder="13:00"
              disabled={!activeDay.morning.open}
              value={activeDay?.morning?.to ? moment(activeDay.morning.to) : ""}
              onChange={(e) => onChangeSchedule(e, day, "morning", "to")}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={5} xl={0}>
        <div
          style={{ borderRight: "1px solid lightgray", height: "100%" }}
        ></div>
      </Col>
      <Col xl={10} xs={19}>
        <Row>
          <Col
            xs={6}
            xl={6}
            style={{ borderRight: "1px solid lightgray", padding: 5 }}
          >
            <Switch
              value={activeDay.afternoon.open}
              onChange={(e) => onChangeSchedule(e, day, "afternoon", "open")}
            />
          </Col>
          <Col
            xs={9}
            xl={9}
            style={{ borderRight: "1px solid lightgray", padding: 5 }}
          >
            <TimePicker
              format={format}
              placeholder="13:00"
              disabled={!activeDay.afternoon.open}
              value={
                activeDay?.afternoon?.from
                  ? moment(activeDay.afternoon.from)
                  : ""
              }
              onChange={(e) => onChangeSchedule(e, day, "afternoon", "from")}
            />
          </Col>
          <Col xs={9} xl={9} style={{ padding: 5 }}>
            <TimePicker
              format={format}
              placeholder="18:00"
              disabled={!activeDay.afternoon.open}
              value={
                activeDay?.afternoon?.to ? moment(activeDay.afternoon.to) : ""
              }
              onChange={(e) => onChangeSchedule(e, day, "afternoon", "to")}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
