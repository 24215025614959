export const linksList = [
  {
    id: 1,
    title: "Accueil",
    href: "/",
  },
  {
    id: 2,
    title: "Nos categories",
    href: "/categories",
  },
  /*{
    id: 3,
    title: "Nos sous categories",
    href: "/sub-categories",
  },*/
  {
    id: 4,
    title: "Nos services",
    href: "/services",
  },
  {
    id: 5,
    title: "Nos prestatrices",
    href: "/prestatrices",
  },
  {
    id: 6,
    title: "Nos réalisations",
    href: "/realisations",
  },
  {
    id: 7,
    title: "Mon compte",
    href: "/account",
  },
];
