import React from "react";
import "./styles.css";
import { HeaderLogo } from "../../atoms";
import HeaderMenu from "../../mollecules/header-menu/HeaderMenu";

function Header() {
  return (
    <header className="header">
      <div className="header-container">
        <HeaderLogo />
        <HeaderMenu />
      </div>
    </header>
  );
}

export default Header;
