import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Switch,
  DatePicker,
  TimePicker,
  Select,
} from "antd";
import dayjs from "dayjs";
import { InfoCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addPrestatrice,
  getPrestatrice,
  updatePrestatrice,
} from "../../../redux/actions/categoryActions";
import {
  defaultSchedule,
  renderDate,
  scheduleOrder,
} from "../schedule-modal/defaultSchedule";
import moment from "moment";
import { filteredDate } from "../../../utils/dateList";
import { colorList } from "../../../utils/colorsList";
import { toast } from "react-toastify";
const format = "HH:mm";
const { Option } = Select;
const { RangePicker } = DatePicker;

function PrestatriceModal(props) {
  const { services, categories } = useSelector(
    (state) => state.categoryReducer
  );
  const { schedule } = useSelector((state) => state.userReducer);
  const { closeModal, toggleModal } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [activeSchedule, setActiveSchedule] = useState(defaultSchedule);
  const [date, setDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [extraDates, setExtraDates] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startWeekDate, setStartWeekDate] = useState("");
  const [endWeekDate, setEndWeekDate] = useState("");
  const [startWeekTime, setStartWeekTime] = useState("");
  const [endWeekTime, setEndWeekTime] = useState("");
  const [holidays, setHolidays] = useState([]);
  const [extraWeeks, setExtraWeeks] = useState([]);

  useEffect(() => {
    if (toggleModal.category) {
      getPrestatrice(toggleModal.category)
        .then((res) => {
          let fields = form.getFieldsValue();
          fields = {
            ...fields,
            image: res.data.image,
            name: res.data.name,
            order: res.data.order,
            services: res.data.services,
            active: res.data.active,
            phone: res.data.phone,
            color: res.data.color,
            email: res.data.email,
          };
          if (res.data?.schedule) {
            setActiveSchedule(res.data.schedule);
          } else if (schedule) {
            setActiveSchedule(schedule);
          }
          setHolidays(res.data.holidays);
          if (res.data.extraDates) {
            setExtraDates(res.data.extraDates);
          }
          if (res.data.extraWeeks) {
            setExtraWeeks(res.data.extraWeeks);
          }
          form.setFieldsValue(fields);
        })
        .catch((err) => console.log(err));

      let options = [];
      for (let i = 0; i < categories.length; i++) {
        let categoryValue = services.filter(
          (service) => service.category === categories[i]._id
        );
        let categoryIds = categoryValue.map((item) => item._id);
        if (categoryIds.length > 0) {
          options.push({
            label: categories[i].name,
            value: categoryIds,
          });
        }
        for (let j; j < categoryValue.length; j++) {
          options.push({
            label: categoryValue[j].name,
            value: categoryValue[j]._id,
          });
        }
      }
      //setOptionsList(options);
    }
  }, [toggleModal, form, schedule, categories, services]);

  const onChangeSchedule = (value, day, moment, label) => {
    setActiveSchedule((prevState) => ({
      ...prevState,
      [day]: {
        ...prevState[day],
        [moment]: {
          ...prevState[day][moment],
          [label]:
            label === "from" || label === "to"
              ? new Date(value).toISOString()
              : value,
        },
      },
    }));
  };

  const onAddPrestatrice = (values) => {
    let body = {
      ...values,
      holidays: holidays,
      extraDates: extraDates,
      schedule: activeSchedule,
      extraWeeks: extraWeeks,
    };
    if (toggleModal.category) {
      updatePrestatrice(toggleModal.category, body, closeModal, true, dispatch);
    } else {
      addPrestatrice(body, closeModal, dispatch);
    }
  };

  const onChangeServices = (categoryId) => {
    const servicesList = services.filter(
      (item) => item.category === categoryId
    );
    const serviceIds = servicesList.map((item) => item._id);
    let newServices = form.getFieldsValue().services;

    for (let i = 0; i < serviceIds.length; i++) {
      if (!newServices.includes(serviceIds[i])) {
        newServices.push(serviceIds[i]);
      }
    }
    let fields = form.getFieldsValue();
    fields = {
      ...fields,
      services: newServices,
    };
    form.setFieldsValue(fields);
  };

  const onChangeDateRange = (values) => {
    setStartDate(values[0]);
    setEndDate(values[1]);
  };

  const onChangeWeekDateRange = (values) => {
    setStartWeekDate(values[0]);
    setEndWeekDate(values[1]);
  };

  const onChangeStartWeekTime = (value) => {
    setStartWeekTime(value);
  };

  const onChangeEndWeekTime = (value) => {
    setEndWeekTime(value);
  };

  const onChangeDate = (values) => {
    setDate(values);
  };

  const onAddHolidays = () => {
    if (!startDate || !endDate) {
      return toast("Veuillez renseigner tous les champs", { type: "error" });
    }
    setHolidays((prevState) => [...prevState, { startDate, endDate }]);
    setStartDate("");
    setEndDate("");
  };

  const onAddExtraWeek = () => {
    if (!startWeekDate || !endWeekDate || !startWeekTime || !endWeekTime) {
      return toast("Veuillez renseigner tous les champs", { type: "error" });
    }
    setExtraWeeks((prevState) => [
      ...prevState,
      {
        startDate: startWeekDate,
        endDate: endWeekDate,
        startTime: startWeekTime,
        endTime: endWeekTime,
      },
    ]);
    setStartWeekDate("");
    setEndWeekDate("");
    setStartWeekTime("");
    setEndWeekTime("");
  };

  const onAddExtraDates = () => {
    if (!date || !startTime || !endTime) {
      return toast("Veuillez renseigner tous les champs", { type: "error" });
    }
    setExtraDates((prevState) => [...prevState, { date, startTime, endTime }]);
    setDate("");
    setStartTime("");
    setEndTime("");
  };

  const onChangeStartTime = (value) => {
    setStartTime(value);
  };

  const onChangeEndTime = (value) => {
    setEndTime(value);
  };

  const onDeleteHolidays = (id) => {
    setHolidays((prevState) => prevState.filter((item, i) => i !== id));
  };

  const onDeleteExtraDates = (id) => {
    setExtraDates((prevState) => prevState.filter((item, i) => i !== id));
  };

  const onDeleteExtraWeeks = (id) => {
    setExtraWeeks((prevState) => prevState.filter((item, i) => i !== id));
  };

  return (
    <Modal
      title={
        toggleModal.category
          ? "Modifier la prestatrice"
          : "Ajouter une prestatrice"
      }
      open={true}
      onCancel={closeModal}
      footer={() => null}
      width={1200}
    >
      <Form
        layout="vertical"
        onFinish={onAddPrestatrice}
        form={form}
        initialValues={{
          image: "",
          name: "",
          order: "",
        }}
      >
        <Row gutter={30}>
          <Col xs={24} xl={8}>
            <Form.Item
              label="Nom de la prestatrice"
              name="name"
              tooltip={{
                title: "Ceci est le nom de la catégorie de prestation",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Veuillez ajouter le nom de la catégorie!",
                },
              ]}
            >
              <Input placeholder="Violetta" />
            </Form.Item>
            <Form.Item
              label="Numéro de téléphone"
              name="phone"
              tooltip={{
                title: "Ceci est le nom de la catégorie de prestation",
                icon: <InfoCircleOutlined />,
              }}
            >
              <Input placeholder="0601010101" />
            </Form.Item>
            <Form.Item
              label="Adresse email"
              name="email"
              rules={[
                {
                  type: "email",
                },
              ]}
              tooltip={{
                title: "Ceci est le nom de la catégorie de prestation",
                icon: <InfoCircleOutlined />,
              }}
            >
              <Input placeholder="hovanessianvioletta@gmail.com" />
            </Form.Item>
            <Form.Item
              label="Ordre d'affichage"
              name="order"
              tooltip={{
                title:
                  "Cette valeur permet de classer l'ordre d'affichage sur le site",
                icon: <InfoCircleOutlined />,
              }}
            >
              <InputNumber placeholder="1" style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item label="Couleur" name="color">
              <Select>
                {colorList.map((color) => (
                  <Option value={color.value}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          height: 20,
                          width: 20,
                          marginRight: 10,
                          backgroundColor: color.value,
                        }}
                      />
                      {color.name}
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} xl={16}>
            <div style={{ marginBottom: 10 }}>
              <div style={{ marginBottom: 10 }}>
                <label>Horaires de la prestatrice</label>
              </div>
              <Row style={{ border: "1px solid gray" }}>
                <Col
                  xs={5}
                  xl={4}
                  style={{ borderRight: "1px solid gray", padding: 5 }}
                >
                  Jour
                </Col>
                <Col
                  xs={5}
                  xl={0}
                  style={{ borderRight: "1px solid gray", padding: 5 }}
                >
                  Ouvert
                </Col>
                <Col
                  xs={7}
                  xl={0}
                  style={{ borderRight: "1px solid gray", padding: 5 }}
                >
                  De
                </Col>
                <Col xs={7} xl={0} style={{ padding: 5 }}>
                  À
                </Col>
                <Col xs={0} xl={10}>
                  <div
                    style={{
                      borderRight: "1px solid gray",
                      borderBottom: "1px solid gray",
                      padding: 5,
                    }}
                  >
                    Matin
                  </div>
                  <Row>
                    <Col
                      xs={6}
                      xl={6}
                      style={{ borderRight: "1px solid gray", padding: 5 }}
                    >
                      Ouvert
                    </Col>
                    <Col
                      xs={9}
                      xl={9}
                      style={{ borderRight: "1px solid gray", padding: 5 }}
                    >
                      De
                    </Col>
                    <Col
                      xs={9}
                      xl={9}
                      style={{ borderRight: "1px solid gray", padding: 5 }}
                    >
                      À
                    </Col>
                  </Row>
                </Col>
                <Col xs={0} xl={10}>
                  <div
                    style={{
                      borderBottom: "1px solid gray",
                      padding: 5,
                    }}
                  >
                    Après-midi
                  </div>
                  <Row>
                    <Col
                      xs={6}
                      xl={6}
                      style={{ borderRight: "1px solid gray", padding: 5 }}
                    >
                      Ouvert
                    </Col>
                    <Col
                      xs={9}
                      xl={9}
                      style={{ borderRight: "1px solid gray", padding: 5 }}
                    >
                      De
                    </Col>
                    <Col xs={9} xl={9} style={{ padding: 5 }}>
                      À
                    </Col>
                  </Row>
                </Col>
              </Row>
              {scheduleOrder.map((day) =>
                renderDate(day, activeSchedule, onChangeSchedule)
              )}
            </div>

            <div>
              <div style={{ marginBottom: 10 }}>
                <label>Congés</label>
              </div>

              {holidays.map((date, i) => (
                <Row className="mb10 pb5 border-bottom" key={i}>
                  <Col xs={24} xl={19}>
                    {filteredDate(date.startDate)} -{" "}
                    {filteredDate(date.endDate)}
                  </Col>
                  <Col xs={24} xl={5}>
                    <Button
                      type="primary"
                      danger
                      onClick={() => onDeleteHolidays(i)}
                    >
                      Supprimer
                    </Button>
                  </Col>
                </Row>
              ))}
              <Row className="mb10 pb5 border-bottom">
                <Col xs={24} xl={19}>
                  <RangePicker
                    className="mr10 mb5"
                    value={[
                      startDate ? moment(startDate) : "",
                      endDate ? moment(endDate) : "",
                    ]}
                    onChange={(value, dateString) =>
                      onChangeDateRange(dateString)
                    }
                  />
                </Col>
                <Col xs={24} xl={5}>
                  <Button type="primary" onClick={onAddHolidays}>
                    Ajouter
                  </Button>
                </Col>
              </Row>
            </div>
            <div>
              <div style={{ marginBottom: 10 }}>
                <label>Date supplémentaires</label>
              </div>

              {extraDates.map((date, i) => (
                <Row className="mb10 pb5 border-bottom" key={i}>
                  <Col xs={24} xl={19}>
                    {filteredDate(date.date)} de {date.startTime} à{" "}
                    {date.endTime}
                  </Col>
                  <Col xs={24} xl={5}>
                    <Button
                      type="primary"
                      danger
                      onClick={() => onDeleteExtraDates(i)}
                    >
                      Supprimer
                    </Button>
                  </Col>
                </Row>
              ))}
              <Row className="mb10 pb5 border-bottom">
                <Col xs={24} xl={19}>
                  <DatePicker
                    className="mr10 mb5"
                    value={date ? moment(date) : ""}
                    onChange={(value, dateString) => onChangeDate(dateString)}
                  />
                  <TimePicker
                    className="mr10 mb5"
                    format={format}
                    placeholder="09:00"
                    value={
                      startTime ? dayjs(startTime + ":00", "HH:mm:ss") : ""
                    }
                    onChange={(e, time) => onChangeStartTime(time)}
                  />
                  <TimePicker
                    className="mb5"
                    format={format}
                    placeholder="18:00"
                    value={endTime ? dayjs(endTime + ":00", "HH:mm:ss") : ""}
                    onChange={(e, time) => onChangeEndTime(time)}
                  />
                </Col>
                <Col xs={24} xl={5}>
                  <Button type="primary" onClick={onAddExtraDates}>
                    Ajouter
                  </Button>
                </Col>
              </Row>
            </div>
            <div>
              <div style={{ marginBottom: 10 }}>
                <label>Semaine supplémentaires</label>
              </div>

              {extraWeeks.map((date, i) => (
                <Row className="mb10 pb5 border-bottom" key={i}>
                  <Col xs={24} xl={19}>
                    {filteredDate(date.startDate)} -{" "}
                    {filteredDate(date.endDate)} : de {date.startTime} à{" "}
                    {date.endTime}
                  </Col>
                  <Col xs={24} xl={5}>
                    <Button
                      type="primary"
                      danger
                      onClick={() => onDeleteExtraWeeks(i)}
                    >
                      Supprimer
                    </Button>
                  </Col>
                </Row>
              ))}
              <Row className="mb10 pb5 border-bottom">
                <Col xs={24} xl={19}>
                  <RangePicker
                    className="mr10 mb5"
                    value={[
                      startWeekDate ? moment(startWeekDate) : "",
                      endWeekDate ? moment(endWeekDate) : "",
                    ]}
                    onChange={(value, dateString) =>
                      onChangeWeekDateRange(dateString)
                    }
                  />
                  <TimePicker
                    className="mr10 mb5"
                    format={format}
                    placeholder="09:00"
                    value={
                      startWeekTime
                        ? dayjs(startWeekTime + ":00", "HH:mm:ss")
                        : ""
                    }
                    onChange={(e, time) => onChangeStartWeekTime(time)}
                  />
                  <TimePicker
                    className="mb5"
                    format={format}
                    placeholder="18:00"
                    value={
                      endWeekTime ? dayjs(endWeekTime + ":00", "HH:mm:ss") : ""
                    }
                    onChange={(e, time) => onChangeEndWeekTime(time)}
                  />
                </Col>
                <Col xs={24} xl={5}>
                  <Button type="primary" onClick={onAddExtraWeek}>
                    Ajouter
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xl={24}>
            <Form.Item
              label="Services"
              name="services"
              tooltip={{
                title:
                  "Cette valeur permet de classer l'ordre d'affichage sur le site",
                icon: <InfoCircleOutlined />,
              }}
            >
              <Checkbox.Group>
                {categories.map((category) => {
                  const servicesList = services.filter(
                    (service) => service.category === category._id
                  );
                  return (
                    servicesList.length > 0 && (
                      <div
                        key={category._id}
                        style={{
                          borderBottom: "1px solid lightgray",
                          padding: "10px 0",
                          width: "100%",
                        }}
                      >
                        <div style={{ width: "100%", display: "flex" }}>
                          <h4 style={{ margin: 0 }}>{category.name}</h4>
                        </div>

                        {servicesList.map((service) => (
                          <Checkbox value={service._id} key={service._id}>
                            {service.name}
                          </Checkbox>
                        ))}
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            marginTop: 10,
                          }}
                          key={category._id}
                        >
                          <div
                            style={{
                              padding: 5,
                              border: "1px solid gray",
                              borderRadius: 10,
                              cursor: "pointer",
                            }}
                            onClick={() => onChangeServices(category._id)}
                          >
                            <h4 style={{ margin: 0 }}>
                              Ajouter tous les services "{category.name}"
                            </h4>
                          </div>
                        </div>
                      </div>
                    )
                  );
                })}
              </Checkbox.Group>
            </Form.Item>
          </Col>
          {toggleModal.category && (
            <Col xl={24}>
              <Form.Item
                label="Active"
                name="active"
                tooltip={{
                  title:
                    "Cette valeur permet de classer l'ordre d'affichage sur le site",
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Switch />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Enregister
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default PrestatriceModal;
