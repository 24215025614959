import { Button, Form, Input, InputNumber, Modal, Select, Switch } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addService,
  getService,
  updateService,
} from "../../../redux/actions/categoryActions";
const { Option } = Select;

const durationArray = [
  { id: 1, value: 30 },
  { id: 2, value: 60 },
  { id: 3, value: 90 },
  { id: 4, value: 120 },
  { id: 5, value: 150 },
  { id: 6, value: 180 },
  { id: 7, value: 210 },
  { id: 8, value: 240 },
];

function ServiceModal(props) {
  const { categories, services } = useSelector(
    (state) => state.categoryReducer
  );
  const { closeModal, toggleModal } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isSubCategory, setIsSubCategory] = useState(false);
  const [subCategoriesList, setSubCategoriesList] = useState([]);

  useEffect(() => {
    if (toggleModal.category) {
      getService(toggleModal.category)
        .then((res) => {
          let fields = form.getFieldsValue();
          fields = {
            ...fields,
            name: res.data.name,
            order: res.data.order,
            duration: res.data.duration,
            price: res.data.price,
            category: res.data.category,
            subCategory: res.data.subCategory,
            active: res.data.active,
            isReservable: res.data.isReservable,
            isOption: res.data.isOption,
          };
          form.setFieldsValue(fields);
          onChangeCategory();
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleModal, form]);

  const onAddService = (body) => {
    if (toggleModal.category) {
      updateService(toggleModal.category, body, closeModal, dispatch);
    } else {
      addService(body, closeModal, dispatch);
    }
  };

  const onChangeCategory = () => {
    const categoryId = form.getFieldValue("category");
    const category =
      categories[categories.findIndex((item) => item._id === categoryId)];
    if (category.short === "Coiffure") {
      setIsSubCategory(true);
      let subCategories = services.filter(
        (ser) => ser.category === categoryId && ser.isOption === false
      );
      setSubCategoriesList(subCategories);
    } else {
      setIsSubCategory(false);
      setSubCategoriesList([]);
    }
  };
  return (
    <Modal
      title={
        toggleModal.category ? "Modifier le service" : "Ajouter un service"
      }
      open={true}
      onCancel={closeModal}
      footer={() => null}
    >
      <Form
        layout="vertical"
        onFinish={onAddService}
        form={form}
        initialValues={{
          name: "",
          order: "",
          active: true,
          isReservable: true,
        }}
      >
        <Form.Item
          label="Nom du service"
          name="name"
          tooltip={{
            title: "Ceci est le nom de la catégorie de prestation",
            icon: <InfoCircleOutlined />,
          }}
          rules={[
            {
              required: true,
              message: "Veuillez ajouter le nom de la catégorie!",
            },
          ]}
        >
          <Input placeholder="Prestation de manicure" />
        </Form.Item>
        <Form.Item
          label="Prix de la prestation"
          name="price"
          tooltip={{
            title:
              "Cette valeur permet de classer l'ordre d'affichage sur le site",
            icon: <InfoCircleOutlined />,
          }}
        >
          <InputNumber placeholder="1" style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          label="Durée de la prestation(en minutes)"
          name="duration"
          tooltip={{
            title:
              "Cette valeur permet de classer l'ordre d'affichage sur le site",
            icon: <InfoCircleOutlined />,
          }}
        >
          <Select>
            {durationArray.map((category) => (
              <Option value={category.value} key={category._id}>
                {category.value}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Catégorie parent"
          name="category"
          tooltip={{
            title: "Ceci est le nom de la catégorie de prestation",
            icon: <InfoCircleOutlined />,
          }}
          rules={[
            {
              required: true,
              message: "Veuillez ajouter le nom de la catégorie!",
            },
          ]}
        >
          <Select onChange={onChangeCategory}>
            {categories.map((category) => (
              <Option value={category._id} key={category._id}>
                {category.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Sous catégorie"
          name="subCategory"
          tooltip={{
            title: "Ceci est le nom de la catégorie de prestation",
            icon: <InfoCircleOutlined />,
          }}
        >
          <Select disabled={!isSubCategory}>
            {subCategoriesList.map((category) => (
              <Option value={category._id} key={category._id}>
                {category.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Ordre d'affichage"
          name="order"
          tooltip={{
            title:
              "Cette valeur permet de classer l'ordre d'affichage sur le site",
            icon: <InfoCircleOutlined />,
          }}
        >
          <InputNumber placeholder="1" style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          label="Reservable"
          name="isReservable"
          tooltip={{
            title:
              "Cette valeur permet de classer l'ordre d'affichage sur le site",
            icon: <InfoCircleOutlined />,
          }}
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label="Option"
          name="isOption"
          tooltip={{
            title:
              "Cette valeur permet de classer l'ordre d'affichage sur le site",
            icon: <InfoCircleOutlined />,
          }}
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label="Active"
          name="active"
          tooltip={{
            title:
              "Cette valeur permet de classer l'ordre d'affichage sur le site",
            icon: <InfoCircleOutlined />,
          }}
        >
          <Switch />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Enregister
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ServiceModal;
