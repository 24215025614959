import { Button } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { AccountModal } from "../../mollecules";

function AccountInformations() {
  const { informations } = useSelector((state) => state.userReducer);
  const [toggleModal, setToggleModal] = useState({
    active: false,
    informations: null,
  });
  const selectModal = () => {
    setToggleModal((prevState) => ({
      ...prevState,
      active: true,
      informations: informations,
    }));
  };
  const openModal = () => {
    setToggleModal((prevState) => ({
      ...prevState,
      active: true,
    }));
  };
  const closeModal = () => {
    setToggleModal((prevState) => ({
      ...prevState,
      active: false,
      informations: null,
    }));
  };
  return (
    <div>
      {toggleModal.active && (
        <AccountModal closeModal={closeModal} toggleModal={toggleModal} />
      )}
      <h2>Informations boutique</h2>
      {informations ? (
        <div>
          <p>Adresse : {informations.address}</p>
          <p>Complément d'adresse : {informations.additionnal}</p>
          <p>Code postal : {informations.zip}</p>
          <p>Ville : {informations.city}</p>
          <p>Numéro de téléphone : {informations.phone}</p>
          <p>Email de contact : {informations.email}</p>
          <Button onClick={selectModal}>
            Modifier les informations de la boutique
          </Button>
        </div>
      ) : (
        <div>
          <Button onClick={openModal}>
            Ajouter les informations de la boutique
          </Button>
        </div>
      )}
    </div>
  );
}

export default AccountInformations;
