import React from "react";
import "./styles.css";
import { Link } from "react-router-dom";

function HeaderLogo() {
  return (
    <Link to="/">
      <img
        src={require("../../../images/logos/logo-3.png")}
        className="header-logo"
        alt="Yeva Nails"
      />
    </Link>
  );
}

export default HeaderLogo;
