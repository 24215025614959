import { combineReducers } from "redux";
import categoryReducer from "./categoryReducer";
import userReducer from "./userReducer";

const rootReducer = combineReducers({
  categoryReducer: categoryReducer,
  userReducer: userReducer,
});

export default rootReducer;
