import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./styles.css";
import {
  updateAdvantageImage,
  updateHomeImage1,
  updateHomeImage2,
  updateOpenningImage,
} from "../../../redux/actions/userActions";

function AccountImages() {
  const dispatch = useDispatch();
  const { informations } = useSelector((state) => state.userReducer);
  const [homeImage1, setHomeImage1] = useState("");
  const [homeImage2, setHomeImage2] = useState("");
  const [advantageImage, setAdvantageImage] = useState("");
  const [openingImage, setOpeningImage] = useState("");
  useEffect(() => {
    if (informations) {
      setHomeImage1(informations.homeImage1);
      setHomeImage2(informations.homeImage2);
      setAdvantageImage(informations.advantageImage);
      setOpeningImage(informations.openingImage);
    }
  }, [informations]);

  const onChangeHomeImage1 = (data) => {
    const image = data.target.files[0];
    const formData = new FormData();
    formData.append("image", image);
    updateHomeImage1(formData, dispatch);
  };

  const onChangeHomeImage2 = (data) => {
    const image = data.target.files[0];
    const formData = new FormData();
    formData.append("image", image);
    updateHomeImage2(formData, dispatch);
  };

  const onChangeAdvantageImage = (data) => {
    const image = data.target.files[0];
    const formData = new FormData();
    formData.append("image", image);
    updateAdvantageImage(formData, dispatch);
  };

  const onChangeOpenningImage = (data) => {
    const image = data.target.files[0];
    const formData = new FormData();
    formData.append("image", image);
    updateOpenningImage(formData, dispatch);
  };

  return (
    <div>
      <h2>Photos du site</h2>
      <Row gutter={[10, 10]}>
        <Col>
          <div>
            <h4>Photo accueil 1</h4>
            <input
              className="home_image_none"
              onChange={(e) => onChangeHomeImage1(e)}
              type="file"
              id="home_image_1"
              name="home_image_1"
            />
            <label htmlFor="home_image_1">
              {homeImage1 ? (
                <img
                  src={homeImage1}
                  alt="preview logo"
                  className="home_image"
                />
              ) : (
                <div className="home_image">+</div>
              )}
            </label>
          </div>
        </Col>
        <Col>
          <div>
            <h4>Photo accueil 2</h4>
            <input
              className="home_image_none"
              onChange={(e) => onChangeHomeImage2(e)}
              type="file"
              id="home_image_2"
              name="home_image_2"
            />
            <label htmlFor="home_image_2">
              {homeImage2 ? (
                <img
                  src={homeImage2}
                  alt="preview logo"
                  className="home_image"
                />
              ) : (
                <div className="home_image">+</div>
              )}
            </label>
          </div>
        </Col>
        <Col>
          <div>
            <h4>Photo avantages</h4>
            <input
              className="home_image_none"
              onChange={(e) => onChangeAdvantageImage(e)}
              type="file"
              id="advantage_image"
              name="advantage_image"
            />
            <label htmlFor="advantage_image">
              {advantageImage ? (
                <img
                  src={advantageImage}
                  alt="preview logo"
                  className="advantage_image"
                />
              ) : (
                <div className="advantage_image">+</div>
              )}
            </label>
          </div>
        </Col>
        <Col span={24}>
          <div>
            <h4>Photo horaires d'ouverture</h4>
            <input
              className="home_image_none"
              onChange={(e) => onChangeOpenningImage(e)}
              type="file"
              id="openning_image"
              name="openning_image"
            />
            <label htmlFor="openning_image">
              {openingImage ? (
                <img
                  src={openingImage}
                  alt="preview logo"
                  className="openning_image"
                />
              ) : (
                <div className="openning_image">+</div>
              )}
            </label>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default AccountImages;
