import {
  ADD_CATEGORY_FAILLED,
  ADD_CATEGORY_REQUEST,
  ADD_CATEGORY_SUCCESS,
  ADD_PRESTATRICE_FAILLED,
  ADD_PRESTATRICE_REQUEST,
  ADD_PRESTATRICE_SUCCESS,
  ADD_REALISATION_FAILLED,
  ADD_REALISATION_REQUEST,
  ADD_REALISATION_SUCCESS,
  ADD_SERVICE_FAILLED,
  ADD_SERVICE_REQUEST,
  ADD_SERVICE_SUCCESS,
  ADD_SUB_CATEGORY_FAILLED,
  ADD_SUB_CATEGORY_REQUEST,
  ADD_SUB_CATEGORY_SUCCESS,
  GET_CATEGORIES_FAILLED,
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
  GET_PRESTATRICES_FAILLED,
  GET_PRESTATRICES_REQUEST,
  GET_PRESTATRICES_SUCCESS,
  GET_REALISATIONS_FAILLED,
  GET_REALISATIONS_REQUEST,
  GET_REALISATIONS_SUCCESS,
  GET_RESERVATIONS_FAILLED,
  GET_RESERVATIONS_REQUEST,
  GET_RESERVATIONS_SUCCESS,
  GET_SERVICES_FAILLED,
  GET_SERVICES_REQUEST,
  GET_SERVICES_SUCCESS,
  GET_SUB_CATEGORIES_FAILLED,
  GET_SUB_CATEGORIES_REQUEST,
  GET_SUB_CATEGORIES_SUCCESS,
  UPDATE_CATEGORY_FAILLED,
  UPDATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_PRESTATRICE_FAILLED,
  UPDATE_PRESTATRICE_REQUEST,
  UPDATE_PRESTATRICE_SUCCESS,
  UPDATE_REALISATION_FAILLED,
  UPDATE_REALISATION_REQUEST,
  UPDATE_REALISATION_SUCCESS,
  UPDATE_SERVICE_FAILLED,
  UPDATE_SERVICE_REQUEST,
  UPDATE_SERVICE_SUCCESS,
  UPDATE_SUB_CATEGORY_FAILLED,
  UPDATE_SUB_CATEGORY_REQUEST,
  UPDATE_SUB_CATEGORY_SUCCESS,
} from "../types/categoryTypes";

const initialeState = {
  categories: [],
  categoryLoading: false,
  subCategories: [],
  subCategoryLoading: false,
  services: [],
  serviceLoading: false,
  prestatrices: [],
  prestatriceLoading: false,
  realisations: [],
  realisationLoading: false,
  reservations: {
    list: [],
    totalCount: 0,
  },
  reservationsCancelled: {
    list: [],
    totalCount: 0,
  },
  reservationLoading: false,
};

const categoryReducer = (state = initialeState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CATEGORIES_REQUEST:
      return {
        ...state,
        categoryLoading: true,
      };
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: payload,
        categoryLoading: false,
      };
    case GET_CATEGORIES_FAILLED:
      return {
        ...state,
        categoryLoading: false,
      };
    case ADD_CATEGORY_REQUEST:
      return {
        ...state,
        categoryLoading: true,
      };
    case ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: payload,
        categoryLoading: false,
      };
    case ADD_CATEGORY_FAILLED:
      return {
        ...state,
        categoryLoading: false,
      };
    case UPDATE_CATEGORY_REQUEST:
      return {
        ...state,
        categoryLoading: true,
      };
    case UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: payload,
        categoryLoading: false,
      };
    case UPDATE_CATEGORY_FAILLED:
      return {
        ...state,
        categoryLoading: false,
      };
    case GET_SUB_CATEGORIES_REQUEST:
      return {
        ...state,
        subCategoryLoading: true,
      };
    case GET_SUB_CATEGORIES_SUCCESS:
      return {
        ...state,
        subCategories: payload,
        subCategoryLoading: false,
      };
    case GET_SUB_CATEGORIES_FAILLED:
      return {
        ...state,
        subCategoryLoading: false,
      };
    case ADD_SUB_CATEGORY_REQUEST:
      return {
        ...state,
        subCategoryLoading: true,
      };
    case ADD_SUB_CATEGORY_SUCCESS:
      return {
        ...state,
        subCategories: payload,
        subCategoryLoading: false,
      };
    case ADD_SUB_CATEGORY_FAILLED:
      return {
        ...state,
        subCategoryLoading: false,
      };
    case UPDATE_SUB_CATEGORY_REQUEST:
      return {
        ...state,
        subCategoryLoading: true,
      };
    case UPDATE_SUB_CATEGORY_SUCCESS:
      return {
        ...state,
        subCategories: payload,
        subCategoryLoading: false,
      };
    case UPDATE_SUB_CATEGORY_FAILLED:
      return {
        ...state,
        subCategoryLoading: false,
      };

    case GET_SERVICES_REQUEST:
      return {
        ...state,
        serviceLoading: true,
      };
    case GET_SERVICES_SUCCESS:
      return {
        ...state,
        services: payload,
        serviceLoading: false,
      };
    case GET_SERVICES_FAILLED:
      return {
        ...state,
        serviceLoading: false,
      };
    case ADD_SERVICE_REQUEST:
      return {
        ...state,
        serviceLoading: true,
      };
    case ADD_SERVICE_SUCCESS:
      return {
        ...state,
        services: payload,
        serviceLoading: false,
      };
    case ADD_SERVICE_FAILLED:
      return {
        ...state,
        serviceLoading: false,
      };

    case UPDATE_SERVICE_REQUEST:
      return {
        ...state,
        serviceLoading: true,
      };
    case UPDATE_SERVICE_SUCCESS:
      return {
        ...state,
        services: payload,
        serviceLoading: false,
      };
    case UPDATE_SERVICE_FAILLED:
      return {
        ...state,
        serviceLoading: false,
      };

    case GET_PRESTATRICES_REQUEST:
      return {
        ...state,
        prestatriceLoading: false,
      };
    case GET_PRESTATRICES_SUCCESS:
      return {
        ...state,
        prestatrices: payload,
        prestatriceLoading: false,
      };
    case GET_PRESTATRICES_FAILLED:
      return {
        ...state,
        prestatriceLoading: false,
      };
    case ADD_PRESTATRICE_REQUEST:
      return {
        ...state,
        prestatriceLoading: true,
      };
    case ADD_PRESTATRICE_SUCCESS:
      return {
        ...state,
        prestatrices: payload,
        prestatriceLoading: false,
      };
    case ADD_PRESTATRICE_FAILLED:
      return {
        ...state,
        prestatriceLoading: false,
      };
    case UPDATE_PRESTATRICE_REQUEST:
      return {
        ...state,
        prestatriceLoading: true,
      };
    case UPDATE_PRESTATRICE_SUCCESS:
      return {
        ...state,
        prestatrices: payload,
        prestatriceLoading: false,
      };
    case UPDATE_PRESTATRICE_FAILLED:
      return {
        ...state,
        prestatriceLoading: false,
      };
    case GET_REALISATIONS_REQUEST:
      return {
        ...state,
        realisationLoading: true,
      };
    case GET_REALISATIONS_SUCCESS:
      return {
        ...state,
        realisations: payload,
        realisationLoading: false,
      };
    case GET_REALISATIONS_FAILLED:
      return {
        ...state,
        realisationLoading: false,
      };
    case ADD_REALISATION_REQUEST:
      return {
        ...state,
        realisationLoading: true,
      };
    case ADD_REALISATION_SUCCESS:
      return {
        ...state,
        realisations: payload,
        realisationLoading: false,
      };
    case ADD_REALISATION_FAILLED:
      return {
        ...state,
        realisationLoading: false,
      };
    case UPDATE_REALISATION_REQUEST:
      return {
        ...state,
        realisationLoading: true,
      };
    case UPDATE_REALISATION_SUCCESS:
      return {
        ...state,
        realisations: payload,
        realisationLoading: false,
      };
    case UPDATE_REALISATION_FAILLED:
      return {
        ...state,
        realisationLoading: false,
      };
    case GET_RESERVATIONS_REQUEST:
      return {
        ...state,
        reservationLoading: true,
      };
    case GET_RESERVATIONS_SUCCESS:
      return {
        ...state,
        reservations: payload.reservations,
        reservationsCancelled: payload.reservationsCancelled,
        reservationLoading: false,
      };
    case GET_RESERVATIONS_FAILLED:
      return {
        ...state,
        reservationLoading: false,
      };
    default:
      return state;
  }
};

export default categoryReducer;
