import { Button, Form, Input, Modal } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import React from "react";
import "./styles.css";
import { useDispatch } from "react-redux";
import { updateInformations } from "../../../redux/actions/userActions";

function AccountModal(props) {
  const { closeModal, toggleModal } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onAddService = (body) => {
    updateInformations(body, closeModal, dispatch);
  };

  return (
    <Modal
      title={
        toggleModal.informations
          ? "Modifier les informations"
          : "Ajouter les informations"
      }
      open={true}
      onCancel={closeModal}
      footer={() => null}
    >
      <Form
        layout="vertical"
        onFinish={onAddService}
        form={form}
        initialValues={
          toggleModal.informations
            ? {
                address: toggleModal.informations.address,
                additionnal: toggleModal.informations.additionnal,
                zip: toggleModal.informations.zip,
                city: toggleModal.informations.city,
                phone: toggleModal.informations.phone,
                email: toggleModal.informations.email,
              }
            : {
                address: "",
                additionnal: "",
                zip: "",
                city: "",
                phone: "",
                email: "",
              }
        }
      >
        <Form.Item
          label="Adresse"
          name="address"
          tooltip={{
            title: "Ceci est le nom de la catégorie de prestation",
            icon: <InfoCircleOutlined />,
          }}
          rules={[
            {
              required: true,
              message: "Veuillez ajouter le nom de la catégorie!",
            },
          ]}
        >
          <Input placeholder="1 rue Jules Juillet" />
        </Form.Item>
        <Form.Item
          label="Complément d'adresse"
          name="additionnal"
          tooltip={{
            title:
              "Cette valeur permet de classer l'ordre d'affichage sur le site",
            icon: <InfoCircleOutlined />,
          }}
        >
          <Input placeholder="Code interphone: 1234 - 2ème étage" />
        </Form.Item>
        <Form.Item
          label="Code postal"
          name="zip"
          tooltip={{
            title: "Ceci est le nom de la catégorie de prestation",
            icon: <InfoCircleOutlined />,
          }}
          rules={[
            {
              required: true,
              message: "Veuillez ajouter le nom de la catégorie!",
            },
          ]}
        >
          <Input placeholder="60100" />
        </Form.Item>
        <Form.Item
          label="Ville"
          name="city"
          tooltip={{
            title: "Ceci est le nom de la catégorie de prestation",
            icon: <InfoCircleOutlined />,
          }}
          rules={[
            {
              required: true,
              message: "Veuillez ajouter le nom de la catégorie!",
            },
          ]}
        >
          <Input placeholder="Creil" />
        </Form.Item>
        <Form.Item
          label="Numéro de téléphone"
          name="phone"
          tooltip={{
            title:
              "Cette valeur permet de classer l'ordre d'affichage sur le site",
            icon: <InfoCircleOutlined />,
          }}
          rules={[
            {
              required: true,
              message: "Veuillez ajouter le nom de la catégorie!",
            },
          ]}
        >
          <Input placeholder="06 84 71 56 41" />
        </Form.Item>
        <Form.Item
          label="Email de contact"
          name="email"
          tooltip={{
            title:
              "Cette valeur permet de classer l'ordre d'affichage sur le site",
            icon: <InfoCircleOutlined />,
          }}
          rules={[
            {
              type: "email",
              required: true,
              message: "Veuillez ajouter le nom de la catégorie!",
            },
          ]}
        >
          <Input placeholder="06 84 71 56 41" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Enregister
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default AccountModal;
