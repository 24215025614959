import { Button, Form, Input, Modal } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePassword } from "../../../redux/actions/userActions";

function PasswordModal(props) {
  const { user, token } = useSelector((state) => state.userReducer);
  const { closeModal } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onUpdatePassword = (values) => {
    updatePassword(token, values, closeModal, dispatch);
  };

  return (
    <Modal
      title="Modifier mon mot de passe"
      open={true}
      onCancel={closeModal}
      width={500}
      footer={() => null}
    >
      <Form
        layout="vertical"
        onFinish={onUpdatePassword}
        form={form}
        initialValues={{
          email: user.email,
          password: "",
        }}
      >
        <Form.Item
          label="Mot de passe actuel"
          name="password"
          rules={[
            {
              required: true,
              message: "Veuillez renseigner votre adresse Email!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Nouveau mot de passe"
          name="newPassword"
          rules={[
            {
              required: true,
              message: "Veuillez renseigner votre mot de passe!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Confirmation du mot de passe"
          name="cofirmPassword"
          dependencies={["newPassword"]}
          hasFeedback
          rules={[
            {
              required: true,
              message:
                "Veuillez renseigner la confirmation votre mot de passe!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  "Le mot de passe et sa confirmation doivent être identiques!"
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Enregister
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default PasswordModal;
