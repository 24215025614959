import axios from "axios";
import {
  ADD_CATEGORY_IMAGE_URL,
  ADD_CATEGORY_URL,
  ADD_PRESTATRICE_URL,
  ADD_REALISATION_IMAGE_URL,
  ADD_REALISATION_URL,
  ADD_SERVICE_IMAGE_URL,
  ADD_SERVICE_URL,
  ADD_SUB_CATEGORY_URL,
  CANCEL_RESERVATION_URL,
  GET_CATEGORIES_URL,
  GET_CATEGORY_URL,
  GET_PRESTATRICES_URL,
  GET_PRESTATRICE_URL,
  GET_REALISATIONS_URL,
  GET_REALISATION_URL,
  GET_RESERVATIONS_URL,
  GET_SERVICES_URL,
  GET_SERVICE_URL,
  GET_SUB_CATEGORIES_URL,
  GET_SUB_CATEGORY_URL,
  RESERVATION_URL,
  UPDATE_CATEGORY_URL,
  UPDATE_PRESTATRICE_URL,
  UPDATE_REALISATION_URL,
  UPDATE_SCHEDULE_URL,
  UPDATE_SERVICE_URL,
  UPDATE_SUB_CATEGORY_URL,
} from "../url/url";
import {
  ADD_CATEGORY_FAILLED,
  ADD_CATEGORY_REQUEST,
  ADD_PRESTATRICE_FAILLED,
  ADD_PRESTATRICE_REQUEST,
  ADD_REALISATION_FAILLED,
  ADD_REALISATION_REQUEST,
  ADD_SERVICE_FAILLED,
  ADD_SERVICE_REQUEST,
  ADD_SUB_CATEGORY_FAILLED,
  ADD_SUB_CATEGORY_REQUEST,
  ADD_SUB_CATEGORY_SUCCESS,
  GET_CATEGORIES_FAILLED,
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
  GET_PRESTATRICES_FAILLED,
  GET_PRESTATRICES_REQUEST,
  GET_PRESTATRICES_SUCCESS,
  GET_REALISATIONS_FAILLED,
  GET_REALISATIONS_REQUEST,
  GET_REALISATIONS_SUCCESS,
  GET_RESERVATIONS_FAILLED,
  GET_RESERVATIONS_REQUEST,
  GET_RESERVATIONS_SUCCESS,
  GET_SERVICES_FAILLED,
  GET_SERVICES_REQUEST,
  GET_SERVICES_SUCCESS,
  GET_SUB_CATEGORIES_FAILLED,
  GET_SUB_CATEGORIES_REQUEST,
  GET_SUB_CATEGORIES_SUCCESS,
  RESERVATION_FAILLED,
  RESERVATION_REQUEST,
  RESERVATION_SUCCESS,
  UPDATE_CATEGORY_FAILLED,
  UPDATE_CATEGORY_REQUEST,
  UPDATE_PRESTATRICE_FAILLED,
  UPDATE_PRESTATRICE_REQUEST,
  UPDATE_REALISATION_FAILLED,
  UPDATE_REALISATION_REQUEST,
  UPDATE_SCHEDULE_FAILLED,
  UPDATE_SCHEDULE_REQUEST,
  UPDATE_SCHEDULE_SUCCESS,
  UPDATE_SERVICE_FAILLED,
  UPDATE_SERVICE_REQUEST,
  UPDATE_SUB_CATEGORY_FAILLED,
  UPDATE_SUB_CATEGORY_REQUEST,
  UPDATE_SUB_CATEGORY_SUCCESS,
} from "../types/categoryTypes";
import { toast } from "react-toastify";

export const addCategoryImage = (body) => {
  return axios.post(ADD_CATEGORY_IMAGE_URL, body);
};

export const addRealisationImage = (body) => {
  return axios.post(ADD_REALISATION_IMAGE_URL, body);
};

export const addServiceImage = (body) => {
  return axios.post(ADD_SERVICE_IMAGE_URL, body);
};

export const addPrestatriceImage = (body) => {
  return axios.post(ADD_SERVICE_IMAGE_URL, body);
};

export const getCategories = (dispatch) => {
  dispatch({
    type: GET_CATEGORIES_REQUEST,
  });
  axios
    .get(GET_CATEGORIES_URL)
    .then((res) => {
      dispatch({
        type: GET_CATEGORIES_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_CATEGORIES_FAILLED,
      });
    });
};

export const getCategory = (id) => {
  return axios.get(`${GET_CATEGORY_URL}/${id}`);
};

export const updateCategory = (id, body, closeModal, dispatch) => {
  dispatch({
    type: UPDATE_CATEGORY_REQUEST,
  });
  axios
    .put(`${UPDATE_CATEGORY_URL}/${id}`, body)
    .then((res) => {
      toast(res.data.message, { type: "success" });
      closeModal();
      getCategories(dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: UPDATE_CATEGORY_FAILLED,
      });
    });
};

export const addCategory = (body, closeModal, dispatch) => {
  dispatch({
    type: ADD_CATEGORY_REQUEST,
  });
  axios
    .post(ADD_CATEGORY_URL, body)
    .then((res) => {
      toast(res.data.message, { type: "success" });
      closeModal();
      getCategories(dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: ADD_CATEGORY_FAILLED,
      });
    });
};

export const getSubCategories = (dispatch) => {
  dispatch({
    type: GET_SUB_CATEGORIES_REQUEST,
  });
  axios
    .get(GET_SUB_CATEGORIES_URL)
    .then((res) => {
      dispatch({
        type: GET_SUB_CATEGORIES_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_SUB_CATEGORIES_FAILLED,
      });
    });
};

export const getSubCategory = (id) => {
  return axios.get(`${GET_SUB_CATEGORY_URL}/${id}`);
};

export const addSubCategory = (body, closeModal, dispatch) => {
  dispatch({
    type: ADD_SUB_CATEGORY_REQUEST,
  });
  axios
    .post(ADD_SUB_CATEGORY_URL, body)
    .then((res) => {
      toast(res.data.message, { type: "success" });
      closeModal();
      dispatch({
        type: ADD_SUB_CATEGORY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: ADD_SUB_CATEGORY_FAILLED,
      });
    });
};

export const updateSubCategory = (id, body, closeModal, dispatch) => {
  dispatch({
    type: UPDATE_SUB_CATEGORY_REQUEST,
  });
  axios
    .put(`${UPDATE_SUB_CATEGORY_URL}/${id}`, body)
    .then((res) => {
      toast(res.data.message, { type: "success" });
      closeModal();
      dispatch({
        payload: res.data,
        type: UPDATE_SUB_CATEGORY_SUCCESS,
      });
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: UPDATE_SUB_CATEGORY_FAILLED,
      });
    });
};

export const getServices = (dispatch) => {
  dispatch({
    type: GET_SERVICES_REQUEST,
  });
  axios
    .get(GET_SERVICES_URL)
    .then((res) => {
      dispatch({
        type: GET_SERVICES_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: GET_SERVICES_FAILLED,
      });
    });
};

export const getService = (id) => {
  return axios.get(`${GET_SERVICE_URL}/${id}`);
};

export const addService = (body, closeModal, dispatch) => {
  dispatch({
    type: ADD_SERVICE_REQUEST,
  });
  axios
    .post(ADD_SERVICE_URL, body)
    .then((res) => {
      toast(res.data.message, { type: "success" });
      closeModal();
      getServices(dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: ADD_SERVICE_FAILLED,
      });
    });
};

export const updateService = (id, body, closeModal, dispatch) => {
  dispatch({
    type: UPDATE_SERVICE_REQUEST,
  });
  axios
    .put(`${UPDATE_SERVICE_URL}/${id}`, body)
    .then((res) => {
      toast(res.data.message, { type: "success" });
      closeModal();
      getServices(dispatch);
      let prestatrices = res.data.prestatrices;
      for (let i = 0; i < prestatrices.length; i++) {
        let prestatrice = prestatrices[i];
        let body = {
          image: prestatrice.image,
          name: prestatrice.name,
          order: prestatrice.order,
          services: prestatrice.services,
          active: prestatrice.active,
          phone: prestatrice.phone,
          color: prestatrice.color,
          holidays: prestatrice.holidays,
          extraDates: prestatrice.extraDates,
          schedule: prestatrice.schedule,
        };
        updatePrestatrice(prestatrice._id, body, null, false, dispatch);
      }
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: UPDATE_SERVICE_FAILLED,
      });
    });
};

export const getSchedule = (dispatch) => {
  dispatch({
    type: UPDATE_SCHEDULE_REQUEST,
  });
  axios
    .get(UPDATE_SCHEDULE_URL)
    .then((res) => {
      dispatch({
        payload: res.data,
        type: UPDATE_SCHEDULE_SUCCESS,
      });
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_SCHEDULE_FAILLED,
      });
    });
};

export const updateSchedule = (body, dispatch) => {
  dispatch({
    type: UPDATE_SCHEDULE_REQUEST,
  });
  axios
    .post(UPDATE_SCHEDULE_URL, body)
    .then((res) => {
      toast(res.data.message, { type: "success" });
      dispatch({
        payload: res.data.schedules,
        type: UPDATE_SCHEDULE_SUCCESS,
      });
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: UPDATE_SCHEDULE_FAILLED,
      });
    });
};

export const getPrestatrices = (dispatch) => {
  dispatch({
    type: GET_PRESTATRICES_REQUEST,
  });
  axios
    .get(GET_PRESTATRICES_URL)
    .then((res) => {
      dispatch({
        type: GET_PRESTATRICES_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_PRESTATRICES_FAILLED,
      });
    });
};

export const getPrestatrice = (id) => {
  return axios.get(`${GET_PRESTATRICE_URL}/${id}`);
};

export const updatePrestatrice = (id, body, closeModal, isToast, dispatch) => {
  dispatch({
    type: UPDATE_PRESTATRICE_REQUEST,
  });
  axios
    .put(`${UPDATE_PRESTATRICE_URL}/${id}`, body)
    .then((res) => {
      if (closeModal) {
        closeModal();
      }
      if (isToast) {
        toast(res.data.message, { type: "success" });
      }
      getPrestatrices(dispatch);
    })
    .catch((err) => {
      if (isToast) {
        toast(err.response.data.message, { type: "error" });
      }
      dispatch({
        type: UPDATE_PRESTATRICE_FAILLED,
      });
    });
};

export const addPrestatrice = (body, closeModal, dispatch) => {
  dispatch({
    type: ADD_PRESTATRICE_REQUEST,
  });
  axios
    .post(ADD_PRESTATRICE_URL, body)
    .then((res) => {
      toast(res.data.message, { type: "success" });
      closeModal();
      getPrestatrices(dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: ADD_PRESTATRICE_FAILLED,
      });
    });
};

export const getRealisations = (dispatch) => {
  dispatch({
    type: GET_REALISATIONS_REQUEST,
  });
  axios
    .get(GET_REALISATIONS_URL)
    .then((res) => {
      dispatch({
        type: GET_REALISATIONS_SUCCESS,
        payload: res.data,
      });
    })
    .catch(() => {
      dispatch({
        type: GET_REALISATIONS_FAILLED,
      });
    });
};

export const getRealisation = (id) => {
  return axios.get(`${GET_REALISATION_URL}/${id}`);
};

export const updateRealisation = (id, body, closeModal, dispatch) => {
  dispatch({
    type: UPDATE_REALISATION_REQUEST,
  });
  axios
    .put(`${UPDATE_REALISATION_URL}/${id}`, body)
    .then((res) => {
      toast(res.data.message, { type: "success" });
      closeModal();
      getRealisations(dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: UPDATE_REALISATION_FAILLED,
      });
    });
};

export const addRealisation = (body, closeModal, dispatch) => {
  dispatch({
    type: ADD_REALISATION_REQUEST,
  });
  axios
    .post(ADD_REALISATION_URL, body)
    .then((res) => {
      closeModal();
      toast(res.data.message, { type: "success" });
      getRealisations(dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: ADD_REALISATION_FAILLED,
      });
    });
};

export const getReservations = (body, dispatch) => {
  dispatch({
    type: GET_RESERVATIONS_REQUEST,
  });
  axios
    .post(GET_RESERVATIONS_URL, body)
    .then((res) => {
      dispatch({
        type: GET_RESERVATIONS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_RESERVATIONS_FAILLED,
      });
    });
};

export const deleteReservation = (id, dispatch) => {
  dispatch({
    type: RESERVATION_REQUEST,
  });
  axios
    .delete(`${RESERVATION_URL}/${id}`)
    .then((res) => {
      toast(res.data.message, { type: "success" });
      getReservations({}, dispatch);
      dispatch({
        type: RESERVATION_SUCCESS,
      });
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: RESERVATION_FAILLED,
      });
    });
};

export const cancelReservation = (id, dispatch) => {
  dispatch({
    type: RESERVATION_REQUEST,
  });
  axios
    .put(`${CANCEL_RESERVATION_URL}/${id}`)
    .then((res) => {
      toast(res.data.message, { type: "success" });
      getReservations({}, dispatch);
      dispatch({
        type: RESERVATION_SUCCESS,
      });
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: RESERVATION_FAILLED,
      });
    });
};
