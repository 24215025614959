import axios from "axios";
import {
  GET_INFORMATIONS_FAILLED,
  GET_INFORMATIONS_REQUEST,
  GET_INFORMATIONS_SUCCESS,
  LOGIN_USER_FAILLED,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  UPDATE_CATEGORY_FAILLED,
  UPDATE_INFORMATIONS_REQUEST,
  UPDATE_INFORMATIONS_SUCCESS,
} from "../types/categoryTypes";
import {
  FORGOT_PASSWORD_URL,
  GET_INFORMATIONS_URL,
  GET_USER_URL,
  LOGIN_USER_URL,
  RESET_PASSWORD_URL,
  UPDATE_ADVANTAGE_IMAGE,
  UPDATE_EMAIL_URL,
  UPDATE_HOME_IMAGE_1,
  UPDATE_HOME_IMAGE_2,
  UPDATE_INFORMATIONS_URL,
  UPDATE_OPENNING_IMAGE,
  UPDATE_PASSWORD_URL,
} from "../url/url";
import { toast } from "react-toastify";

export const loginUser = (body, goToHome, dispatch) => {
  dispatch({
    type: LOGIN_USER_REQUEST,
  });
  axios
    .post(LOGIN_USER_URL, body)
    .then((res) => {
      toast(res.data.message, { type: "success" });
      goToHome();
      dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: LOGIN_USER_FAILLED,
      });
    });
};

export const getUser = (body, dispatch) => {
  dispatch({
    type: LOGIN_USER_REQUEST,
  });
  axios
    .post(GET_USER_URL, body)
    .then((res) => {
      dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: res.data,
      });
    })
    .catch(() => {
      dispatch({
        type: LOGIN_USER_FAILLED,
      });
    });
};

export const getInformations = (dispatch) => {
  dispatch({
    type: GET_INFORMATIONS_REQUEST,
  });
  axios
    .get(GET_INFORMATIONS_URL)
    .then((res) => {
      dispatch({
        type: GET_INFORMATIONS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_INFORMATIONS_FAILLED,
      });
    });
};

export const updateInformations = (body, closeModal, dispatch) => {
  dispatch({
    type: UPDATE_INFORMATIONS_REQUEST,
  });
  axios
    .post(UPDATE_INFORMATIONS_URL, body)
    .then((res) => {
      closeModal();
      dispatch({
        type: UPDATE_INFORMATIONS_SUCCESS,
        payload: res.data.informations,
      });
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_CATEGORY_FAILLED,
      });
    });
};

export const updateHomeImage1 = (body, dispatch) => {
  dispatch({
    type: UPDATE_INFORMATIONS_REQUEST,
  });
  axios
    .put(UPDATE_HOME_IMAGE_1, body)
    .then((res) => {
      dispatch({
        type: UPDATE_INFORMATIONS_SUCCESS,
        payload: res.data.informations,
      });
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_CATEGORY_FAILLED,
      });
    });
};

export const updateHomeImage2 = (body, dispatch) => {
  dispatch({
    type: UPDATE_INFORMATIONS_REQUEST,
  });
  axios
    .put(UPDATE_HOME_IMAGE_2, body)
    .then((res) => {
      dispatch({
        type: UPDATE_INFORMATIONS_SUCCESS,
        payload: res.data.informations,
      });
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_CATEGORY_FAILLED,
      });
    });
};

export const updateAdvantageImage = (body, dispatch) => {
  dispatch({
    type: UPDATE_INFORMATIONS_REQUEST,
  });
  axios
    .put(UPDATE_ADVANTAGE_IMAGE, body)
    .then((res) => {
      dispatch({
        type: UPDATE_INFORMATIONS_SUCCESS,
        payload: res.data.informations,
      });
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_CATEGORY_FAILLED,
      });
    });
};

export const updateOpenningImage = (body, dispatch) => {
  dispatch({
    type: UPDATE_INFORMATIONS_REQUEST,
  });
  axios
    .put(UPDATE_OPENNING_IMAGE, body)
    .then((res) => {
      dispatch({
        type: UPDATE_INFORMATIONS_SUCCESS,
        payload: res.data.informations,
      });
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_CATEGORY_FAILLED,
      });
    });
};

export const updateEmail = (token, body, closeModal, dispatch) => {
  dispatch({
    type: UPDATE_INFORMATIONS_REQUEST,
  });
  axios
    .put(UPDATE_EMAIL_URL, body, { headers: { "auth-token": token } })
    .then((res) => {
      closeModal();
      toast(res.data.message, { type: "success" });
      let newBody = {
        token,
      };
      getUser(newBody, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: UPDATE_CATEGORY_FAILLED,
      });
    });
};

export const updatePassword = (token, body, closeModal, dispatch) => {
  dispatch({
    type: UPDATE_INFORMATIONS_REQUEST,
  });
  axios
    .put(UPDATE_PASSWORD_URL, body, { headers: { "auth-token": token } })
    .then((res) => {
      closeModal();
      toast(res.data.message, { type: "success" });
      let newBody = {
        token,
      };
      getUser(newBody, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: UPDATE_CATEGORY_FAILLED,
      });
    });
};

export const logoutUser = (dispatch) => {
  dispatch({ type: LOGOUT_USER });
};

export const askCode = (body) => {
  return axios.post(FORGOT_PASSWORD_URL, body);
};

export const resetPassword = (body) => {
  return axios.post(RESET_PASSWORD_URL, body);
};