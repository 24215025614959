import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Switch,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import "./styles.css";
import {
  addCategory,
  addCategoryImage,
  //addServiceImage,
  getCategory,
  updateCategory,
} from "../../../redux/actions/categoryActions";
import { useDispatch } from "react-redux";

function CategoryModal(props) {
  const { closeModal, toggleModal } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [categoryImage, setCategoryImage] = useState(null);
  /*const [prestationImage1, setPrestationImage1] = useState(null);
  const [prestationImage2, setPrestationImage2] = useState(null);*/

  useEffect(() => {
    if (toggleModal.category) {
      getCategory(toggleModal.category)
        .then((res) => {
          let fields = form.getFieldsValue();
          fields = {
            ...fields,
            categoryImage: res.data.categoryImage,
            prestationImage1: res.data.prestationImage1,
            prestationImage2: res.data.prestationImage2,
            name: res.data.name,
            order: res.data.order,
            active: res.data.active,
            url: res.data.url,
            short: res.data.short,
          };
          setCategoryImage(res.data.categoryImage);
          form.setFieldsValue(fields);
        })
        .catch((err) => console.log(err));
    }
  }, [toggleModal, form]);

  const onChangeCategoryImage = (data) => {
    const image = data.target.files[0];
    const formData = new FormData();
    formData.append("image", image);
    addCategoryImage(formData).then((res) => {
      let fields = form.getFieldsValue();
      fields = {
        ...fields,
        categoryImage: res.data,
      };
      form.setFieldsValue(fields);
      setCategoryImage(res.data);
    });
  };

  /*const onChangePrestationImage1 = (data) => {
    const image = data.target.files[0];
    const formData = new FormData();
    formData.append("image", image);
    addServiceImage(formData).then((res) => {
      let fields = form.getFieldsValue();
      fields = {
        ...fields,
        prestationImage1: res.data,
      };
      form.setFieldsValue(fields);
      setPrestationImage1(res.data);
    });
  };

  const onChangePrestationImage2 = (data) => {
    const image = data.target.files[0];
    const formData = new FormData();
    formData.append("image", image);
    addServiceImage(formData).then((res) => {
      let fields = form.getFieldsValue();
      fields = {
        ...fields,
        prestationImage2: res.data,
      };
      form.setFieldsValue(fields);
      setPrestationImage2(res.data);
    });
  };*/

  const onAddCategory = (body) => {
    if (toggleModal.category) {
      updateCategory(toggleModal.category, body, closeModal, dispatch);
    } else {
      addCategory(body, closeModal, dispatch);
    }
  };

  return (
    <Modal
      title={
        toggleModal.category ? "Modifier la catégorie" : "Ajouter une catégorie"
      }
      open={true}
      onCancel={closeModal}
      footer={() => null}
      className="category_modal_container"
    >
      <Form
        layout="vertical"
        onFinish={onAddCategory}
        form={form}
        initialValues={{
          categoryImage: "",
          prestationImage1: "",
          prestationImage2: "",
          name: "",
          order: "",
          short: "",
        }}
      >
        <Row gutter={[30, 30]}>
          <Col xs={24} xl={12}>
            <Form.Item
              label="Nom de la catégorie"
              name="name"
              tooltip={{
                title: "Ceci est le nom de la catégorie de prestation",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Veuillez ajouter le nom de la catégorie!",
                },
              ]}
            >
              <Input placeholder="Prestation de manicure" />
            </Form.Item>
            <Form.Item
              label="Nom court"
              name="short"
              tooltip={{
                title: "Ceci est le nom de la catégorie de prestation",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Veuillez ajouter le nom de la catégorie!",
                },
              ]}
            >
              <Input placeholder="Prestation de manicure" />
            </Form.Item>
            <Form.Item
              label="Ordre d'affichage"
              name="order"
              tooltip={{
                title:
                  "Cette valeur permet de classer l'ordre d'affichage sur le site",
                icon: <InfoCircleOutlined />,
              }}
            >
              <InputNumber placeholder="1" style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              label="Url de la catégorie"
              name="url"
              tooltip={{
                title:
                  "Cette valeur permet de classer l'ordre d'affichage sur le site",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Veuillez ajouter le nom de la catégorie!",
                },
              ]}
            >
              <Input placeholder="/manicure" />
            </Form.Item>
            {toggleModal.category && (
              <Form.Item
                label="Active"
                name="active"
                tooltip={{
                  title:
                    "Cette valeur permet de classer l'ordre d'affichage sur le site",
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Switch />
              </Form.Item>
            )}
          </Col>
          <Col xs={24} xl={12}>
            <Form.Item
              label="Photo"
              name="categoryImage"
              rules={[
                {
                  required: true,
                  message:
                    "Veuillez ajouter l'image principale de la catégorie!",
                },
              ]}
            >
              <div>
                <input
                  className="product_form_input_none"
                  onChange={(e) => onChangeCategoryImage(e)}
                  type="file"
                  id="new_category_image"
                  name="new_category_image"
                />
                <label htmlFor="new_category_image">
                  {categoryImage ? (
                    <img
                      src={categoryImage}
                      alt="preview logo"
                      className="product_form_image"
                    />
                  ) : (
                    <div className="product_form_image">+</div>
                  )}
                </label>
              </div>
            </Form.Item>
          </Col>
          {/*<Col xl={12}>
            <Form.Item
              label="Photo"
              name="prestationImage1"
              rules={[
                {
                  required: true,
                  message:
                    "Veuillez ajouter la première image à afficher dans les prestations!",
                },
              ]}
            >
              <div>
                <input
                  className="product_form_input_none"
                  onChange={(e) => onChangePrestationImage1(e)}
                  type="file"
                  id="new_prestation1_image"
                  name="new_prestation1_image"
                />
                <label htmlFor="new_prestation1_image">
                  {prestationImage1 !== null ? (
                    <img
                      src={prestationImage1}
                      alt="preview logo"
                      className="product_form_image"
                    />
                  ) : (
                    <div className="product_form_image">+</div>
                  )}
                </label>
              </div>
            </Form.Item>
          </Col>
          <Col xl={12}>
            <Form.Item
              label="Photo"
              name="prestationImage2"
              rules={[
                {
                  required: true,
                  message:
                    "Veuillez ajouter la seconde image à afficher dans les prestations!",
                },
              ]}
            >
              <div>
                <input
                  className="product_form_input_none"
                  onChange={(e) => onChangePrestationImage2(e)}
                  type="file"
                  id="new_prestation2_image"
                  name="new_prestation2_image"
                />
                <label htmlFor="new_prestation2_image">
                  {prestationImage2 !== null ? (
                    <img
                      src={prestationImage2}
                      alt="preview logo"
                      className="product_form_image"
                    />
                  ) : (
                    <div className="product_form_image">+</div>
                  )}
                </label>
              </div>
            </Form.Item>
          </Col>*/}
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Enregister
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CategoryModal;
