import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { askCode, resetPassword } from "../../../redux/actions/userActions";
import { toast } from "react-toastify";

function ForgotPassword() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isCodeSended, setIsCodeSended] = useState(false);
  const onAskCode = (values) => {
    askCode(values)
      .then((res) => {
        toast(res.data.message, { type: "success" });
        setIsCodeSended(true);
      })
      .catch((err) => {
        toast(err.response.data.message, { type: "error" });
      });
  };
  const onResetPassword = (values) => {
    resetPassword(values)
      .then((res) => {
        toast(res.data.message, { type: "success" });
        navigate("/");
      })
      .catch((err) => {
        toast(err.response.data.message, { type: "error" });
      });
  };

  return (
    <div className="auth-form">
      {isCodeSended ? (
        <Form
          name="basic"
          layout="vertical"
          form={form}
          initialValues={{ remember: true }}
          onFinish={onResetPassword}
          autoComplete="off"
        >
          <Form.Item
            label="Adresse Email"
            name="email"
            rules={[
              {
                type: "email",
                required: true,
                message: "Renseignez une adresse Email valide!",
              },
            ]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label="Code de réinitialisation"
            name="code"
            rules={[
              { required: true, message: "Renseignez le code reçu par Email!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Nouveau mot de passe"
            name="password"
            rules={[
              {
                required: true,
                message: "Renseignez votre nouveau mot de passe!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Confirmer le mot de passe"
            name="confirmPassword"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Renseignez la confirmation du mot de passe!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "Le mot de passe et sa confirmation doivent être identiques!"
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Realisation mon mot de passe
            </Button>
          </Form.Item>
          <Link to="/login">Se connecter</Link>
        </Form>
      ) : (
        <Form
          name="basic"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onAskCode}
          autoComplete="off"
        >
          <Form.Item
            label="Adresse Email"
            name="email"
            rules={[
              {
                type: "email",
                required: true,
                message: "Renseignez une adresse Email valide!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Demander un code de realisation du mot de passe
            </Button>
          </Form.Item>
          <Link to="/login">Se connecter</Link>
        </Form>
      )}
    </div>
  );
}

export default ForgotPassword;
