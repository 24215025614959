import { Button, Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import "./styles.css";

function PrestatricesList(props) {
  const { editModal } = props;
  const { prestatrices, services } = useSelector(
    (state) => state.categoryReducer
  );

  return (
    <div>
      <Row className="home_head_table">
        <Col xl={1} className="h-auto">
          <div className="home_head_table_cell">Ordre</div>
        </Col>
        <Col xl={9} className="h-auto">
          <div className="home_head_table_cell">Nom</div>
        </Col>
        <Col xl={9} className="h-auto">
          <div className="home_head_table_cell">Services</div>
        </Col>
        <Col xl={2} className="h-auto">
          <div className="home_head_table_cell">Active</div>
        </Col>
        <Col xl={2} className="h-auto">
          <div className="px5 py10 h-full">Action</div>
        </Col>
      </Row>
      {prestatrices.map((prestatrice) => (
        <Row key={prestatrice._id} className="home_body_table">
          <Col xs={2} xl={1} className="h-auto">
            <div className="home_head_table_cell home_body_table_text_left text-center">
              {prestatrice.order}
            </div>
          </Col>
          <Col xs={22} xl={9} className="h-auto">
            <div className="home_head_table_cell home_body_table_text_right home_body_table_title">
              {prestatrice.name}
            </div>
          </Col>
          <Col xs={24} xl={9} className="h-auto">
            <div className="home_head_table_cell home_body_table_text_right">
              {prestatrice.services.map((service, i) => {
                const index = services.findIndex(
                  (item) => item._id === service
                );
                return (
                  i < 3 && <div key={service}>- {services[index].name}</div>
                );
              })}
              {prestatrice.services.length > 3 && (
                <div>...(+{prestatrice.services.length - 3})</div>
              )}
            </div>
          </Col>
          <Col xs={24} xl={2} className="h-auto">
            <div className="home_head_table_cell home_body_table_text_right">
              {prestatrice.active ? (
                <div className="active-item">Active</div>
              ) : (
                <div className="inactive-item">Inactive</div>
              )}
            </div>
          </Col>
          <Col xs={24} xl={2} className="h-auto">
            <div className="p5 h-full">
              <Button
                className="w-full"
                type="primary"
                onClick={() => editModal(prestatrice._id)}
              >
                Modifier
              </Button>
            </div>
          </Col>
        </Row>
      ))}
    </div>
  );
}

export default PrestatricesList;
