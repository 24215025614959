import { Button, Table } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import "./styles.css";

function SubCategoriesList(props) {
  const { editModal } = props;
  const { subCategories, categories } = useSelector(
    (state) => state.categoryReducer
  );

  const columns = [
    {
      title: "Ordre",
      dataIndex: "order",
      key: "order",
    },
    {
      title: "Nom",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Catégorie",
      dataIndex: "category",
      key: "category",
      render: (value) => {
        const index = categories.findIndex((item) => item._id === value);
        return <div>{categories[index].name}</div>;
      },
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      render: (value) => {
        return value ? (
          <div className="active-item">Active</div>
        ) : (
          <div className="inactive-item">Inactive</div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (value, row) => {
        return <Button onClick={() => editModal(row._id)}>Modifier</Button>;
      },
    },
  ];

  return (
    <div>
      <Table
        bordered
        dataSource={subCategories}
        columns={columns}
        rowKey={(category) => category._id}
        pagination={false}
      />
    </div>
  );
}

export default SubCategoriesList;
