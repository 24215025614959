import { Button, Form, Input, InputNumber, Modal, Select, Switch } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import React, { useEffect } from "react";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addSubCategory,
  getSubCategory,
  updateSubCategory,
} from "../../../redux/actions/categoryActions";
const { Option } = Select;

function SubCategoryModal(props) {
  const { categories } = useSelector((state) => state.categoryReducer);
  const { closeModal, toggleModal } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    if (toggleModal.category) {
      getSubCategory(toggleModal.category)
        .then((res) => {
          let fields = form.getFieldsValue();
          fields = {
            ...fields,
            name: res.data.name,
            order: res.data.order,
            category: res.data.category,
            active: res.data.active,
          };
          form.setFieldsValue(fields);
        })
        .catch((err) => console.log(err));
    }
  }, [toggleModal, form]);

  const onAddCategory = (body) => {
    if (toggleModal.category) {
      updateSubCategory(toggleModal.category, body, closeModal, dispatch);
    } else {
      addSubCategory(body, closeModal, dispatch);
    }
  };

  return (
    <Modal
      title={
        toggleModal.category
          ? "Modifier la sous-catégorie"
          : "Ajouter une sous-catégorie"
      }
      open={true}
      onCancel={closeModal}
      footer={() => null}
    >
      <Form
        layout="vertical"
        onFinish={onAddCategory}
        form={form}
        initialValues={{
          name: "",
          order: "",
        }}
      >
        <Form.Item
          label="Nom de la sous-catégorie"
          name="name"
          tooltip={{
            title: "Ceci est le nom de la catégorie de prestation",
            icon: <InfoCircleOutlined />,
          }}
          rules={[
            {
              required: true,
              message: "Veuillez ajouter le nom de la catégorie!",
            },
          ]}
        >
          <Input placeholder="Prestation de manicure" />
        </Form.Item>
        <Form.Item
          label="Catégorie parent"
          name="category"
          tooltip={{
            title: "Ceci est le nom de la catégorie de prestation",
            icon: <InfoCircleOutlined />,
          }}
          rules={[
            {
              required: true,
              message: "Veuillez ajouter le nom de la catégorie!",
            },
          ]}
        >
          <Select>
            {categories.map((category) => (
              <Option value={category._id} key={category._id}>
                {category.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Ordre d'affichage"
          name="order"
          tooltip={{
            title:
              "Cette valeur permet de classer l'ordre d'affichage sur le site",
            icon: <InfoCircleOutlined />,
          }}
        >
          <InputNumber placeholder="1" style={{ width: "100%" }} />
        </Form.Item>
        {toggleModal.category && (
          <Form.Item
            label="Active"
            name="active"
            tooltip={{
              title:
                "Cette valeur permet de classer l'ordre d'affichage sur le site",
              icon: <InfoCircleOutlined />,
            }}
          >
            <Switch />
          </Form.Item>
        )}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Enregister
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default SubCategoryModal;
