import { Button, Col, Row } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ScheduleModal } from "../../mollecules";
import { scheduleOrder } from "../../mollecules/schedule-modal/defaultSchedule";
const format = {
  hour: "2-digit",
  minute: "2-digit",
};

function AccountSchedule() {
  const { schedule } = useSelector((state) => state.userReducer);
  const [toggleModal, setToggleModal] = useState({
    active: false,
    category: null,
  });
  const openModal = () => {
    setToggleModal((prevState) => ({
      ...prevState,
      active: true,
    }));
  };
  const closeModal = () => {
    setToggleModal((prevState) => ({
      ...prevState,
      active: false,
      category: null,
    }));
  };
  const renderSchedule = (day) => {
    const activeDay = schedule[day];
    return (
      <div key={activeDay.index}>
        <Row>
          <Col xl={3} xs={6}>
            {activeDay.name}
          </Col>
          {!activeDay?.morning?.open && !activeDay?.afternoon?.open ? (
            <Col>Fermé</Col>
          ) : (
            <>
              {activeDay?.morning?.open && (
                <Col xl={4} xs={9}>
                  {activeDay.morning?.from &&
                    new Date(activeDay.morning.from).toLocaleTimeString(
                      "fr",
                      format
                    )}{" "}
                  -{" "}
                  {activeDay.morning?.to &&
                    new Date(activeDay.morning.to).toLocaleTimeString(
                      "fr",
                      format
                    )}
                </Col>
              )}
              {activeDay?.afternoon?.open && (
                <Col xl={4} xs={9}>
                  {activeDay?.afternoon?.from &&
                    new Date(activeDay.afternoon.from).toLocaleTimeString(
                      "fr",
                      format
                    )}{" "}
                  -{" "}
                  {activeDay?.afternoon?.to &&
                    new Date(activeDay.afternoon.to).toLocaleTimeString(
                      "fr",
                      format
                    )}
                </Col>
              )}
            </>
          )}
        </Row>
      </div>
    );
  };
  return (
    <div>
      {toggleModal.active && (
        <ScheduleModal closeModal={closeModal} toggleModal={toggleModal} />
      )}
      <h2>Horaires d'ouverture</h2>
      <div>
        {schedule && scheduleOrder.map((day) => renderSchedule(day))}
        <div style={{ marginTop: 10 }}>
          <Button onClick={openModal}>Ajouter les horaires d'ouverture</Button>
        </div>
      </div>
    </div>
  );
}

export default AccountSchedule;
