import { Button, Col, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./styles.css";
const { Option } = Select;

function ServicesList(props) {
  const { editModal } = props;
  const { categories, services } = useSelector(
    (state) => state.categoryReducer
  );
  const [activeServices, setActiveServices] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");

  useEffect(() => {
    let newServices = [...services];
    if (selectedCategory) {
      newServices = newServices.filter(
        (service) => service.category === selectedCategory
      );
    }
    setActiveServices(newServices);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [services]);

  const onSelectCategory = (categoryId) => {
    setSelectedCategory(categoryId);
    if (categoryId) {
      setActiveServices(
        services.filter((service) => service.category === categoryId)
      );
    } else {
      setActiveServices([...services]);
    }
  };

  return (
    <div>
      <Select
        style={{ width: 300 }}
        onSelect={onSelectCategory}
        value={selectedCategory}
      >
        <Option value="">-Tous-</Option>
        {categories.map((category) => (
          <Option value={category._id} key={category._id}>
            {category.name}
          </Option>
        ))}
      </Select>
      <div>
        <Row className="home_head_table">
          <Col xl={1} className="h-auto">
            <div className="home_head_table_cell">Ordre</div>
          </Col>
          <Col xl={5} className="h-auto">
            <div className="home_head_table_cell">Nom</div>
          </Col>
          <Col xl={2} className="h-auto">
            <div className="home_head_table_cell">Prix</div>
          </Col>
          <Col xl={2} className="h-auto">
            <div className="home_head_table_cell">Durée</div>
          </Col>
          <Col xl={3} className="h-auto">
            <div className="home_head_table_cell">Catégorie</div>
          </Col>
          <Col xl={3} className="h-auto">
            <div className="home_head_table_cell">Sous-catégorie</div>
          </Col>
          <Col xl={2} className="h-auto">
            <div className="home_head_table_cell">Active</div>
          </Col>
          <Col xl={2} className="h-auto">
            <div className="home_head_table_cell">Réservable</div>
          </Col>
          <Col xl={2} className="h-auto">
            <div className="home_head_table_cell">Option</div>
          </Col>
          <Col xl={2} className="h-auto">
            <div className="px5 py10 h-full">Action</div>
          </Col>
        </Row>
        {activeServices.map((service) => (
          <Row key={service._id} className="home_body_table">
            <Col xs={2} xl={1} className="h-auto">
              <div className="home_head_table_cell home_body_table_text_left text-center">
                {service.order}
              </div>
            </Col>
            <Col xs={22} xl={5} className="h-auto">
              <div className="home_head_table_cell home_body_table_text_right home_body_table_title">
                {service.name}
              </div>
            </Col>
            <Col xs={12} xl={2} className="h-auto">
              <div className="home_head_table_cell home_body_table_text_left">
                {service.price}€
              </div>
            </Col>
            <Col xs={12} xl={2} className="h-auto">
              <div className="home_head_table_cell home_body_table_text_right">
                {service.duration} minutes
              </div>
            </Col>
            <Col xs={12} xl={3} className="h-auto">
              <div className="home_head_table_cell home_body_table_text_left">
                {categories.findIndex(
                  (item) => item._id === service.category
                ) >= 0
                  ? categories[
                      categories.findIndex(
                        (item) => item._id === service.category
                      )
                    ].name
                  : ""}
              </div>
            </Col>
            <Col xs={12} xl={3} className="h-auto">
              <div className="home_head_table_cell home_body_table_text_right">
                {services.findIndex(
                  (item) => item._id === service.subCategory
                ) >= 0
                  ? services[
                      services.findIndex(
                        (item) => item._id === service.subCategory
                      )
                    ].name
                  : ""}
              </div>
            </Col>
            <Col xs={8} xl={2} className="h-auto">
              <div className="home_head_table_cell home_body_table_text_left">
                {service.active ? (
                  <div className="active-item">Active</div>
                ) : (
                  <div className="inactive-item">Inactive</div>
                )}
              </div>
            </Col>
            <Col xs={8} xl={2} className="h-auto">
              <div className="home_head_table_cell home_body_table_text_left">
                {service.isReservable ? (
                  <div className="active-item">Réservable</div>
                ) : (
                  <div className="inactive-item">Non réservable</div>
                )}
              </div>
            </Col>
            <Col xs={8} xl={2} className="h-auto">
              <div className="home_head_table_cell home_body_table_text_right">
                {service.isOption ? (
                  <div className="active-item">Option</div>
                ) : (
                  <div></div>
                )}
              </div>
            </Col>
            <Col xs={24} xl={2} className="h-auto">
              <div className="p5 h-full">
                <Button
                  className="w-full"
                  type="primary"
                  onClick={() => editModal(service._id)}
                >
                  Modifier
                </Button>
              </div>
            </Col>
          </Row>
        ))}
      </div>
    </div>
  );
}

export default ServicesList;
