import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./styles.css";
import { Header } from "../components/templates";
import {
  Account,
  Categories,
  ForgotPassword,
  Home,
  Login,
  Prestatrices,
  Realisations,
  ResetPassword,
  Services,
} from "../pages";
import {
  getCategories,
  getPrestatrices,
  getRealisations,
  getReservations,
  getSchedule,
  getServices,
  getSubCategories,
} from "../redux/actions/categoryActions";
import { useDispatch, useSelector } from "react-redux";
import { getInformations, getUser } from "../redux/actions/userActions";

function Navigation(props) {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.userReducer);
  useEffect(() => {
    const tokenFromStorage = localStorage.getItem("token");
    if (tokenFromStorage) {
      getCategories(dispatch);
      getSubCategories(dispatch);
      getServices(dispatch);
      getInformations(dispatch);
      getSchedule(dispatch);
      getPrestatrices(dispatch);
      getRealisations(dispatch);
      const reservationBody = {
        page: 1,
        prestatrice: null,
      };
      getReservations(reservationBody, dispatch);
      const body = {
        token: tokenFromStorage,
      };
      getUser(body, dispatch);
    }
  }, [dispatch, token]);
  const appRouter = () => (
    <Router>
      <Header />
      <main className="app_container">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/home" element={<Navigate replace to="/" />} />
          <Route exact path="/categories" element={<Categories />} />
          {/*<Route exact path="/sub-categories" element={<SubCategories />} />*/}
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/prestatrices" element={<Prestatrices />} />
          <Route exact path="/realisations" element={<Realisations />} />
          <Route exact path="/account" element={<Account />} />
          <Route exact path="/*" element={<Navigate replace to="/" />} />
        </Routes>
      </main>
    </Router>
  );
  const authRouter = () => (
    <Router>
      <main className="login_container">
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/reset-password" element={<ResetPassword />} />
          <Route exact path="/*" element={<Navigate replace to="/login" />} />
        </Routes>
      </main>
    </Router>
  );
  return token ? appRouter() : authRouter();
}

export default Navigation;
