import { Button } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { EmailModal, PasswordModal } from "../../mollecules";

function AccountDetails() {
  const { user } = useSelector((state) => state.userReducer);
  const [toggleEmailModal, setToggleEmailModal] = useState(false);
  const [togglePasswordModal, setTogglePasswordModal] = useState(false);
  const onShowEmailModal = () => {
    setToggleEmailModal(true);
  };
  const onCloseEmailModal = () => {
    setToggleEmailModal(false);
  };
  const onShowPasswordModal = () => {
    setTogglePasswordModal(true);
  };
  const onClosePasswordModal = () => {
    setTogglePasswordModal(false);
  };
  return (
    <div>
      {toggleEmailModal && <EmailModal closeModal={onCloseEmailModal} />}
      {togglePasswordModal && (
        <PasswordModal closeModal={onClosePasswordModal} />
      )}
      <h2>Mon compte</h2>
      <p>Adresse Email : {user.email}</p>
      <div style={{ marginBottom: 10 }}>
        <Button onClick={onShowEmailModal}>Modifier mon adresse Email</Button>
      </div>
      <div>
        <Button onClick={onShowPasswordModal}>Modifier mon mot de passe</Button>
      </div>
    </div>
  );
}

export default AccountDetails;
