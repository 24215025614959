import {
  Col,
  Collapse,
  DatePicker,
  Input,
  Pagination,
  Row,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filteredDate } from "../../../utils/dateList";
import { getReservations } from "../../../redux/actions/categoryActions";
import Papa from "papaparse";
import axios from "axios";
import "./styles.css";
import moment from "moment";
import { useDebounce } from "use-debounce";
const { Option } = Select;
      const api = process.env.REACT_APP_API_URL;
      function HomeTemplate() {
        const dispatch = useDispatch();
        const { reservations, prestatrices } = useSelector(
          (state) => state.categoryReducer
        );
        const [filter, setFilter] = useState({
          page: 1,
          service: "",
          prestatrice: "",
          date: "",
        });

        const [value] = useDebounce(filter, 500);

        useEffect(() => {
          getReservations(filter, dispatch);
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [dispatch, value]);

        const onChangePage = (value) => {
          setFilter((prevState) => ({
            ...prevState,
            page: value,
          }));
        };
        const onChangeService = (e) => {
          setFilter((prevState) => ({
            ...prevState,
            service: e.target.value,
          }));
        };
        const onChangePrestatrice = (value) => {
          setFilter((prevState) => ({
            ...prevState,
            prestatrice: value,
          }));
        };
        const onChangeDate = (value) => {
          setFilter((prevState) => ({
            ...prevState,
            date: value,
          }));
        };
        const handleChange = (value) => {
          let newArray = [];
          for (let i = 0; i < value.length; i++) {
            if (value[i] && i > 0) {
              if (value[i][1] && value[i][3]) {
                const date = new Date(value[i][1] + " " + value[i][3]);
                const hours =
                  date.getHours() < 10
                    ? "0" + date.getHours()
                    : date.getHours();
                const minutes =
                  date.getMinutes() < 10
                    ? "0" + date.getMinutes()
                    : date.getMinutes();
                let object = {
                  firstname: value[i][13],
                  lastname: value[i][14],
                  email: value[i][18],
                  phone: value[i][8],
                  date: value[i][1],
                  time: hours + ":" + minutes,
                  service: {
                    _id: "6604af1f59ac0b25a5f60cb7",
                    name: "POSE COMPLÈTE CHABLON SUR LES ONGLES NATURELS",
                    duration: "90",
                    price: "60",
                  },
                  category: {
                    _id: "65fb72299507e5ed2fa8130d",
                    name: "Prestation de manucure",
                    short: "Manucure",
                  },
                  prestatrice: value[i][6],
                  i: i,
                };
                newArray.push(object);
              }
            }
          }
          for (let i = 0; i < newArray.length; i++) {
            const body = newArray[i];
            if (body) {
              axios
                .post(`${api}/admin/old-reservations`, body)
                .then((res) => console.log(res.data.message))
                .catch((err) => console.log(err.response.data.message));
            }
          }
        };

        const renderFilters = () => (
          <Row className="pb5">
            <Col xs={24} xl={5}>
              <div className="p2">
                <label className="home_input_label">Numéro de téléphone</label>
                <Input value={filter.phone} onChange={onChangeService} />
              </div>
            </Col>
            <Col xs={24} xl={3} className="h-auto">
              <div className="p2">
                <label className="home_input_label">Date</label>
                <DatePicker
                  className="w-full"
                  disabledDate={(current) => {
                    return current < moment().add(-1, "days");
                  }}
                  onChange={(value, dateString) => onChangeDate(dateString)}
                />
              </div>
            </Col>
            <Col xs={24} xl={2}></Col>
            <Col xs={24} xl={2}></Col>
            <Col xs={24} xl={4}></Col>
            <Col xs={24} xl={2}></Col>
            <Col xs={24} xl={3}>
              <div className="p2">
                <label className="home_input_label">Prestatrice</label>
                <Select
                  className="w-full"
                  onSelect={onChangePrestatrice}
                  value={filter.prestatrice}
                >
                  <Option value="">-Tous-</Option>
                  {prestatrices.map((prestatrice) => (
                    <Option value={prestatrice._id} key={prestatrice._id}>
                      {prestatrice.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </Col>
            <Col xs={24} xl={3}></Col>
            <Col xs={0} xl={1} />
          </Row>
        );
        const items = [
          {
            key: "1",
            label: "Filtres",
            children: renderFilters(),
          },
        ];
        return (
          <div>
            {process.env.REACT_APP_ENV === "DEV" && (
              <input
                type="file"
                accept=".csv,.xlsx,.xls"
                onChange={(e) => {
                  const files = e.target.files;
                  if (files) {
                    Papa.parse(files[0], {
                      complete: function (results) {
                        handleChange(results.data);
                      },
                    });
                  }
                }}
              />
            )}
            <div className="home_top_page">
              <div className="my10">
                <Pagination
                  total={reservations.totalCount}
                  pageSize={50}
                  onChange={onChangePage}
                  current={filter.page}
                  showSizeChanger={false}
                />
              </div>
              <div className="home_filters_container">{renderFilters()}</div>
              <div className="home_filters_container_mobile">
                <Collapse size="small" items={items} defaultActiveKey={[""]} />
              </div>
            </div>
            <div>
              <Row className="home_head_table">
                <Col xl={5} className="h-auto">
                  <div className="home_head_table_cell">Prestations</div>
                </Col>
                <Col xl={2} className="h-auto">
                  <div className="home_head_table_cell">Date</div>
                </Col>
                <Col xl={1} className="h-auto">
                  <div className="home_head_table_cell">Heure</div>
                </Col>
                <Col xl={2} className="h-auto">
                  <div className="home_head_table_cell">Prénom</div>
                </Col>
                <Col xl={2} className="h-auto">
                  <div className="home_head_table_cell">Nom</div>
                </Col>
                <Col xl={4} className="h-auto">
                  <div className="home_head_table_cell">Email</div>
                </Col>
                <Col xl={2} className="h-auto">
                  <div className="home_head_table_cell">Téléphone</div>
                </Col>
                <Col xl={3} className="h-auto">
                  <div className="home_head_table_cell">Prestatrice</div>
                </Col>
                <Col xl={2} className="h-auto">
                  <div className="px5 py10 h-full">Réservé le</div>
                </Col>
              </Row>
              {reservations.list.map((reservation) => (
                <Row key={reservation._id} className="home_body_table">
                  <Col xs={24} xl={5} className="h-auto">
                    <div className="home_head_table_cell home_body_table_title_border">
                      <div className="text-bold home_body_table_title">
                        {reservation.service.name}
                      </div>
                      <div>
                        {reservation.options.map((option) => (
                          <Row>
                            <Col span={2}>-</Col>
                            <Col span={22}>{option.name}</Col>
                          </Row>
                        ))}
                      </div>
                    </div>
                  </Col>
                  <Col xs={13} xl={2} className="h-auto">
                    <div className="home_head_table_cell home_body_table_text_left">
                      {filteredDate(reservation.date)}
                    </div>
                  </Col>
                  <Col xs={11} xl={1} className="h-auto">
                    <div className="home_head_table_cell home_body_table_text_right">
                      {reservation.time}
                    </div>
                  </Col>
                  <Col xs={13} xl={2} className="h-auto">
                    <div className="home_head_table_cell home_body_table_text_left">
                      {reservation.firstname}
                    </div>
                  </Col>
                  <Col xs={11} xl={2} className="h-auto">
                    <div className="home_head_table_cell home_body_table_text_right">
                      {reservation.lastname}
                    </div>
                  </Col>
                  <Col xs={13} xl={4} className="h-auto">
                    <div className="home_head_table_cell home_body_table_text_left">
                      {reservation.email}
                    </div>
                  </Col>
                  <Col xs={11} xl={2} className="h-auto">
                    <div className="home_head_table_cell home_body_table_text_right">
                      {reservation.phone}
                    </div>
                  </Col>
                  <Col xs={13} xl={3} className="h-auto">
                    <div className="home_head_table_cell home_body_table_text_left">
                      {reservation.prestatrice.name}
                    </div>
                  </Col>
                  <Col xs={11} xl={2} className="h-auto">
                    <div className="p5 h-full">
                      {new Date(reservation.createdAt).toLocaleString("fr")}
                    </div>
                  </Col>
                </Row>
              ))}
            </div>
          </div>
        );
      }

export default HomeTemplate;
