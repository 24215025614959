import {
  GET_INFORMATIONS_FAILLED,
  GET_INFORMATIONS_REQUEST,
  GET_INFORMATIONS_SUCCESS,
  LOGIN_USER_FAILLED,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  UPDATE_INFORMATIONS_FAILLED,
  UPDATE_INFORMATIONS_REQUEST,
  UPDATE_INFORMATIONS_SUCCESS,
  UPDATE_SCHEDULE_FAILLED,
  UPDATE_SCHEDULE_REQUEST,
  UPDATE_SCHEDULE_SUCCESS,
} from "../types/categoryTypes";

const initialeState = {
  user: null,
  token: null,
  informations: null,
  schedule: null,
  userLoading: false,
};

const userReducer = (state = initialeState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_USER_REQUEST:
      return {
        ...state,
        userLoading: true,
      };
    case LOGIN_USER_SUCCESS:
      localStorage.setItem("token", payload.token);
      return {
        ...state,
        userLoading: false,
        user: payload.user,
        token: payload.token,
      };
    case LOGIN_USER_FAILLED:
      return {
        ...state,
        userLoading: false,
      };
    case GET_INFORMATIONS_REQUEST:
      return {
        ...state,
        userLoading: true,
        informations: payload,
      };
    case GET_INFORMATIONS_SUCCESS:
      return {
        ...state,
        userLoading: false,
        informations: payload,
      };
    case GET_INFORMATIONS_FAILLED:
      return {
        ...state,
        userLoading: false,
      };
    case UPDATE_INFORMATIONS_REQUEST:
      return {
        ...state,
        userLoading: true,
        informations: payload,
      };
    case UPDATE_INFORMATIONS_SUCCESS:
      return {
        ...state,
        userLoading: false,
        informations: payload,
      };
    case UPDATE_INFORMATIONS_FAILLED:
      return {
        ...state,
        userLoading: false,
      };

    case UPDATE_SCHEDULE_REQUEST:
      return {
        ...state,
        userLoading: true,
      };
    case UPDATE_SCHEDULE_SUCCESS:
      return {
        ...state,
        schedule: payload,
        userLoading: false,
      };
    case UPDATE_SCHEDULE_FAILLED:
      return {
        ...state,
        userLoading: false,
      };
    case LOGOUT_USER:
      localStorage.removeItem("token");
      return {
        ...state,
        user: null,
        token: null,
        informations: null,
        schedule: null,
        userLoading: false,
      };
    default:
      return state;
  }
};

export default userReducer;
