import React, { useState } from "react";
import { OpelModal, RealisationsList } from "../../organisms";
import { RealisationModal } from "../../mollecules";

function RealisationsTemplate() {
  const [toggleModal, setToggleModal] = useState({
    active: false,
    category: null,
  });

  const openModal = () => {
    setToggleModal((prevState) => ({
      ...prevState,
      active: true,
    }));
  };
  const closeModal = () => {
    setToggleModal((prevState) => ({
      ...prevState,
      active: false,
      category: null,
    }));
  };
  const editModal = (id) => {
    setToggleModal((prevState) => ({
      ...prevState,
      active: true,
      category: id,
    }));
  };
  return (
    <div>
      {toggleModal.active && (
        <RealisationModal closeModal={closeModal} toggleModal={toggleModal} />
      )}
      <OpelModal title="Ajouter une réalisation" openModal={openModal} />
      <RealisationsList editModal={editModal} />
    </div>
  );
}

export default RealisationsTemplate;
