import { Button, Table } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import "./styles.css";

function RealisationsList(props) {
  const { editModal } = props;
  const { realisations } = useSelector((state) => state.categoryReducer);

  const columns = [
    {
      title: "Ordre",
      dataIndex: "order",
      key: "order",
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (value) => {
        return (
          <img src={value} alt="preview logo" className="category_image_list" />
        );
      },
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      render: (value) => {
        return value ? (
          <div className="active-item">Active</div>
        ) : (
          <div className="inactive-item">Inactive</div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (value, row) => {
        return <Button onClick={() => editModal(row._id)}>Modifier</Button>;
      },
    },
  ];

  return (
    <div>
      <Table
        bordered
        dataSource={realisations}
        columns={columns}
        rowKey={(category) => category._id}
        pagination={false}
      />
    </div>
  );
}

export default RealisationsList;
