import React from "react";
import {
  AccountDetails,
  AccountImages,
  AccountInformations,
  AccountSchedule,
} from "../../organisms";
import { Button, Col, Row } from "antd";
import { logoutUser } from "../../../redux/actions/userActions";
import { useDispatch } from "react-redux";

function AccountTemplate() {
  const dispatch = useDispatch();
  const onLogoutUser = () => {
    logoutUser(dispatch);
  };
  return (
    <div>
      <Row gutter={30}>
        <Col xs={24} xl={12}>
          <div
            style={{
              padding: 10,
              border: "1px solid lightgray",
              marginBottom: 10,
            }}
          >
            <AccountDetails />
          </div>
          <div
            style={{
              padding: 10,
              border: "1px solid lightgray",
              marginBottom: 10,
            }}
          >
            <AccountInformations />
          </div>
          <div
            style={{
              padding: 10,
              border: "1px solid lightgray",
              marginBottom: 10,
            }}
          >
            <AccountSchedule />
          </div>
        </Col>
        <Col xs={24} xl={12}>
          <div
            style={{
              padding: 10,
              border: "1px solid lightgray",
              marginBottom: 10,
            }}
          >
            <AccountImages />
          </div>
          <div style={{ marginTop: 30 }}>
            <Button onClick={onLogoutUser}>Déconnexion</Button>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default AccountTemplate;
