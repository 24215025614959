export const colorList = [
  { id: 1, name: "Violet", value: "#4B0082" },
  { id: 2, name: "Bleu", value: "#00008B" },
  { id: 3, name: "Marron", value: "#A52A2A" },
  { id: 4, name: "Olive", value: "#556B2F" },
  { id: 5, name: "Rouge", value: "#8B0000" },
  { id: 6, name: "Gris", value: "#2F4F4F" },
  { id: 7, name: "Vert", value: "#006400" },
  { id: 8, name: "Magenta", value: "#8B008B" },
  { id: 9, name: "Khaki", value: "#BDB76B" },
  { id: 10, name: "Orange", value: "#FF8C00" },
  { id: 11, name: "Saumon", value: "#E9967A" },
];
