import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./styles.css";

function HeaderLink(props) {
  const { link } = props;
  const pathname = useLocation().pathname;
  return (
    <Link
      to={link.href}
      className={pathname === link.href ? "header-link-active" : "header-link"}
    >
      {link.title}
    </Link>
  );
}

export default HeaderLink;
