const api = process.env.REACT_APP_API_URL;

export const LOGIN_USER_URL = `${api}/admin/login-user`;
export const GET_USER_URL = `${api}/admin/get-user`;
export const GET_INFORMATIONS_URL = `${api}/admin/get-informations`;
export const UPDATE_INFORMATIONS_URL = `${api}/admin/update-informations`;
export const UPDATE_SCHEDULE_URL = `${api}/admin/schedule`;

export const UPDATE_HOME_IMAGE_1 = `${api}/admin/update-home-image-1`;
export const UPDATE_HOME_IMAGE_2 = `${api}/admin/update-home-image-2`;
export const UPDATE_ADVANTAGE_IMAGE = `${api}/admin/update-advantage-image`;
export const UPDATE_OPENNING_IMAGE = `${api}/admin/update-openning-image`;

export const ADD_CATEGORY_IMAGE_URL = `${api}/admin/new-category-image`;
export const ADD_SERVICE_IMAGE_URL = `${api}/admin/new-service-image`;
export const ADD_PRESTATRICE_IMAGE_URL = `${api}/admin/new-service-image`;
export const ADD_REALISATION_IMAGE_URL = `${api}/admin/new-realisation-image`;

export const GET_CATEGORIES_URL = `${api}/admin/categories`;
export const GET_CATEGORY_URL = `${api}/admin/category`;
export const ADD_CATEGORY_URL = `${api}/admin/add-category`;
export const UPDATE_CATEGORY_URL = `${api}/admin/update-category`;

export const GET_SUB_CATEGORIES_URL = `${api}/admin/sub-categories`;
export const GET_SUB_CATEGORY_URL = `${api}/admin/sub-category`;
export const ADD_SUB_CATEGORY_URL = `${api}/admin/add-sub-category`;
export const UPDATE_SUB_CATEGORY_URL = `${api}/admin/update-sub-category`;

export const GET_SERVICES_URL = `${api}/admin/services`;
export const GET_SERVICE_URL = `${api}/admin/service`;
export const ADD_SERVICE_URL = `${api}/admin/add-service`;
export const UPDATE_SERVICE_URL = `${api}/admin/update-service`;

export const GET_PRESTATRICES_URL = `${api}/admin/prestatrices`;
export const GET_PRESTATRICE_URL = `${api}/admin/prestatrice`;
export const ADD_PRESTATRICE_URL = `${api}/admin/add-prestatrice`;
export const UPDATE_PRESTATRICE_URL = `${api}/admin/update-prestatrice`;

export const GET_REALISATIONS_URL = `${api}/admin/realisations`;
export const GET_REALISATION_URL = `${api}/admin/realisation`;
export const ADD_REALISATION_URL = `${api}/admin/add-realisation`;
export const UPDATE_REALISATION_URL = `${api}/admin/update-realisation`;

export const GET_RESERVATIONS_URL = `${api}/admin/reservations`;
export const RESERVATION_URL = `${api}/admin/reservation`;
export const CANCEL_RESERVATION_URL = `${api}/admin/cancel-reservation`;

export const UPDATE_EMAIL_URL = `${api}/admin/update-email`;
export const UPDATE_PASSWORD_URL = `${api}/admin/update-password`;
export const FORGOT_PASSWORD_URL = `${api}/admin/forgot-password`;
export const RESET_PASSWORD_URL = `${api}/admin/reset-password`;

